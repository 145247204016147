import {
  contractQueryKeys,
  ErrorCode,
  formatDate,
  getTagColorByOfferState,
  IOffer,
  numberWithCommas,
  queryClient,
  useUploadContractMutation,
} from '@nayla/common';
import useStyles from './styles';
import Table, { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@stryberventures/gaia-react.table';
import dayjs from 'dayjs';
import Tag from '@stryberventures/gaia-react.tag';
import adminVocab from '@/vocabulary';
import Button from '@stryberventures/gaia-react.button';
import { DocumentIcon } from '@stryberventures/gaia-react.icons';
import theme from '@nayla/ui/styles';
import { enqueueSnackbar } from 'notistack';

const headCells = [
  { id: 'date', label: 'Date Provided' },
  { id: 'amount', label: 'Amount' },
  { id: 'terms', label: 'Terms' },
  { id: 'monthlyProfitRate', label: 'Monthly Profit Rate' },
  { id: 'adminFees', label: 'Admin Fees' },
  { id: 'status', label: 'Status' },
];

interface IOffersTableProps {
  offers: IOffer[];
  loanApplicationId?: string;
  amlPassed?: boolean;
}

export const OffersTable = ({ offers = [], loanApplicationId, amlPassed }: IOffersTableProps) => {
  const classes = useStyles();
  const { mutate: uploadContract } = useUploadContractMutation({
    onSettled: () => {
      queryClient.invalidateQueries(contractQueryKeys.contractsPerLoanApplication(loanApplicationId, ['offer']));
    },
    onSuccess: () => {
      enqueueSnackbar('Contract uploaded successfully', { variant: 'success' });
    },
    onError: (error) => {
      if (typeof error === 'object' && error && 'errorCode' in error && error?.errorCode === ErrorCode.FORBIDDEN) {
        enqueueSnackbar(adminVocab.errors.accessDenied, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    },
  });

  if (!offers.length) {
    return (
      <div className={classes.noOffers}>
        <p>{adminVocab.loanApplication.offer.noOffers}</p>
      </div>
    );
  }

  // Sort offers by creation date (newest first) and find the last accepted offer
  const sortedOffers = [...offers].sort((a, b) => dayjs(b?.createdAt).valueOf() - dayjs(a?.createdAt).valueOf());
  const lastAcceptedOfferIndex = sortedOffers.findIndex((offer) => offer.state === 'ACCEPTED');

  return (
    <>
      <div className={classes.container}>
        <p>{adminVocab.loanApplication.offer.runAMLMessage}</p>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {[...headCells, ...(amlPassed && loanApplicationId ? [{ id: 'contract', label: 'Contract' }] : [])].map(
                (headCell) => (
                  <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedOffers.map((offer: IOffer, index: number) => {
              const showContractButton = amlPassed && loanApplicationId && index === lastAcceptedOfferIndex;

              return (
                <TableRow key={index}>
                  <TableCell>{formatDate(offer?.createdAt)}</TableCell>
                  <TableCell>{numberWithCommas(offer?.amount)}</TableCell>
                  <TableCell>{offer?.terms}</TableCell>
                  <TableCell>{offer?.monthlyProfitRate}</TableCell>
                  <TableCell>{offer?.adminFees}</TableCell>
                  <TableCell>
                    {offer.state ? (
                      <Tag color={getTagColorByOfferState(offer.state)}>
                        {adminVocab.loanApplication.offer.states[offer.state]}
                      </Tag>
                    ) : (
                      adminVocab.na
                    )}
                  </TableCell>
                  {showContractButton && (
                    <TableCell>
                      <Button
                        onClick={() =>
                          uploadContract({
                            offerId: offer.id,
                            loanApplicationId,
                          })
                        }
                        iconRight={<DocumentIcon width={16} height={16} fill={theme.colors.primary.main500} />}
                        className={classes.btn}
                        variant="outlined"
                      >
                        {adminVocab.loanApplication.contract.sendNewContract}
                      </Button>
                    </TableCell>
                  )}
                  {!showContractButton && amlPassed && loanApplicationId && <TableCell />}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OffersTable;
