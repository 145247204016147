import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Id, LoanApplicationCardType } from '@/components/LoanApplicationBoard/types';
import useStyles from './styles';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';
import adminVocab from '@/vocabulary';
import { LoanApplicationCard } from '@/components/LoanApplicationBoard/LoanApplicationCard';
import { ColumnContainer } from '@/components/LoanApplicationBoard/ColumnContainer';
import Dialog from '@stryberventures/gaia-react.dialog';
import Button from '@stryberventures/gaia-react.button';
import {
  ILoanApplication,
  loanApplicationQueryKeys,
  LoanApplicationState,
  queryClient,
  useApproveLoanApplicationMutation,
  useSignLoanApplicationMutation,
  useUnderwriteLoanApplicationMutation,
} from '@nayla/common';
import vocab from '@nayla/vocabulary';
import { enqueueSnackbar } from 'notistack';

export interface ILoanApplicationBoardProps {
  data: ILoanApplication[];
}

type State = {
  SUBMISSIONS: string[];
  FINAL_RISK_ASSESSMENT: string[];
  CONTRACT_NEGOTIATION: string[];
  DISBURSEMENT: string[];
};

interface StateToColumnIdMapping {
  [state: string]: string;
}

const STATES: State = {
  SUBMISSIONS: [LoanApplicationState.SUBMIT],
  FINAL_RISK_ASSESSMENT: [LoanApplicationState.UNDERWRITER_LEVEL_1, LoanApplicationState.UNDERWRITER_LEVEL_2],
  CONTRACT_NEGOTIATION: [
    LoanApplicationState.CREATE_OFFER,
    LoanApplicationState.ACCEPT_OFFER,
    LoanApplicationState.COMMODITY_BUY,
    LoanApplicationState.COMMODITY_SELL,
    LoanApplicationState.AML,
    LoanApplicationState.CONTRACT,
    LoanApplicationState.IBAN,
    LoanApplicationState.PENDING_IVR,
    LoanApplicationState.IN_PROGRESS_IVR,
  ],
  DISBURSEMENT: [LoanApplicationState.PENDING_DISBURSE, LoanApplicationState.SUCCESS_DISBURSE],
};

type KeyOfStates = keyof typeof STATES;

const columnStates = (Object.keys(STATES) as KeyOfStates[]).map((key) => ({
  id: key,
  title: adminVocab.loanApplication.columnStates[key],
}));

const stateToColumnId: StateToColumnIdMapping = Object.entries(STATES).reduce((acc, [key, states]) => {
  states.forEach((state) => (acc[state] = key));
  return acc;
}, {} as StateToColumnIdMapping);

export const LoanApplicationBoard = ({ data }: ILoanApplicationBoardProps) => {
  const classes = useStyles();
  const [modalShown, setModalShown] = React.useState(false);
  const [previousColumnId, setPreviousColumnId] = React.useState<Id>('');
  const [loanApplicationCards, setLoanApplicationCards] = useState<LoanApplicationCardType[]>([]);
  const [activeLoanApplicationCard, setActiveLoanApplicationCard] = useState<LoanApplicationCardType | null>(null);
  const [transitionState, setTransitionState] = useState<LoanApplicationState | null>(null);
  console.log('loanApplicationCards', loanApplicationCards);
  function cancelStateChange() {
    setLoanApplicationCards((cards) => {
      const cardIndex = cards.findIndex((card) => card.id === activeLoanApplicationCard?.id);
      const card = cards[cardIndex];

      if (!card) {
        return cards;
      }

      card.columnId = previousColumnId;

      return arrayMove(cards, cardIndex, cardIndex);
    });
    setPreviousColumnId('');
    setActiveLoanApplicationCard(null);
    setModalShown(false);
  }

  const { mutate: moveToUnderwriting } = useUnderwriteLoanApplicationMutation({
    onSuccess: () => {
      enqueueSnackbar(adminVocab.loanApplication.transitions.underwriting, { variant: 'success' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(loanApplicationQueryKeys.loanApplications(['business']));
    },
    onError: (error) => {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else if (typeof error === 'string') {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        enqueueSnackbar('An unknown error occurred', { variant: 'error' });
      }
      cancelStateChange();
    },
  });
  const { mutate: moveToApproved } = useApproveLoanApplicationMutation({
    onSuccess: () => {
      enqueueSnackbar(adminVocab.loanApplication.transitions.approved, { variant: 'success' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(loanApplicationQueryKeys.loanApplications(['business']));
    },
    onError: (error) => {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else if (typeof error === 'string') {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        enqueueSnackbar('An unknown error occurred', { variant: 'error' });
      }
      cancelStateChange();
    },
  });
  const { mutate: moveToSigned } = useSignLoanApplicationMutation({
    onSuccess: () => {
      enqueueSnackbar(adminVocab.loanApplication.transitions.signed, { variant: 'success' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(loanApplicationQueryKeys.loanApplications(['business']));
    },
    onError: (error) => {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else if (typeof error === 'string') {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        enqueueSnackbar('An unknown error occurred', { variant: 'error' });
      }
      cancelStateChange();
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 100,
      },
    }),
  );

  const mapLoanApplicationToCard = useCallback(
    (loanApplications: ILoanApplication[]) => {
      const lps = loanApplications.map((loanApplication) => {
        const card = {
          id: loanApplication.id,
          loanAppId: loanApplication.externalId,
          stateLabel:
            loanApplication.state && adminVocab.loanApplication.states[loanApplication.state].substring(0, 10),
          state: loanApplication.state,
          columnId: stateToColumnId[loanApplication.state] ?? '',
          createdAt: loanApplication.createdAt,
          companyName: loanApplication?.business?.companyName?.substring(0, 10) || '',
        };
        console.log('Mapped card:', card);
        console.log('Mapped card:', card);
        console.log('Mapped card:', card);
        console.log('Mapped card:', card);
        console.log('Mapped card:', card);
        console.log('Mapped card:', card);
        console.log('Mapped card:', card);
        return card;
      });
      setLoanApplicationCards(lps);
    },
    [setLoanApplicationCards],
  );

  useEffect(() => {
    mapLoanApplicationToCard(data);
  }, [data, mapLoanApplicationToCard]);

  const confirmStateChange = () => {
    setModalShown(false);
    switch (transitionState) {
      case LoanApplicationState.UNDERWRITER_LEVEL_1:
        moveToUnderwriting(activeLoanApplicationCard?.id.toString());
        return;
      case LoanApplicationState.CREATE_OFFER:
        moveToApproved(activeLoanApplicationCard?.id.toString());
        return;
      case LoanApplicationState.PENDING_DISBURSE:
        moveToSigned(activeLoanApplicationCard?.id.toString());
        return;
    }
    setPreviousColumnId('');
    setActiveLoanApplicationCard(null);
  };

  const onDragStart = (event: DragStartEvent) => {
    if (event.active.data.current?.type === 'Card') {
      setActiveLoanApplicationCard(event.active.data.current.loanCard);
      setPreviousColumnId(event.active.data.current.loanCard.columnId);
      return;
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) {
      /* not dragging over a valid element */
      return;
    }

    const activeId = active.id;
    const overId = over.id;
    //const isOverAColumn = over.data.current?.type === 'Column'
    const newColumnId = active.data.current?.loanCard?.columnId;

    switch (newColumnId) {
      // case 'SUBMISSIONS':
      //     break;
      case 'FINAL_RISK_ASSESSMENT':
        if (
          previousColumnId === 'SUBMISSIONS' &&
          activeLoanApplicationCard &&
          [LoanApplicationState.SUBMIT].includes(activeLoanApplicationCard?.state)
        ) {
          setModalShown(true);
          setTransitionState(LoanApplicationState.UNDERWRITER_LEVEL_1);
          return;
        }
        break;
      case 'CONTRACT_NEGOTIATION':
        if (
          previousColumnId === 'FINAL_RISK_ASSESSMENT' &&
          activeLoanApplicationCard &&
          [LoanApplicationState.UNDERWRITER_LEVEL_2].includes(activeLoanApplicationCard?.state)
        ) {
          setModalShown(true);
          setTransitionState(LoanApplicationState.CREATE_OFFER);
          return;
        }
        break;
      case 'DISBURSEMENT':
        if (
          previousColumnId === 'CONTRACT_NEGOTIATION' &&
          activeLoanApplicationCard &&
          [LoanApplicationState.PENDING_IVR, LoanApplicationState.IN_PROGRESS_IVR].includes(
            activeLoanApplicationCard?.state,
          )
        ) {
          setModalShown(true);
          setTransitionState(LoanApplicationState.PENDING_DISBURSE);
          return;
        }
        break;
    }

    cancelStateChange();

    // if (newColumnId !== previousColumnId) {
    //     setModalShown(true);
    //   }
    // }

    if (activeId === overId) {
      /* not dragging over a different element */
      return;
    }
  };

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    if (!over) {
      /* not dragging over a valid element */
      return;
    }

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) {
      /* not dragging over a different element */
      return;
    }

    const isActiveLoanApplicationCard = active.data.current?.type === 'Card';
    const isOverLoanApplicationCard = over.data.current?.type === 'Card';

    if (!isActiveLoanApplicationCard) {
      return;
    }

    // dropping a card over another card
    if (isActiveLoanApplicationCard && isOverLoanApplicationCard) {
      setLoanApplicationCards((cards) => {
        const activeCardIndex = cards.findIndex((card) => card.id === activeId);
        const overCardIndex = cards.findIndex((card) => card.id === overId);
        const cardActive = cards[activeCardIndex];
        const cardOver = cards[overCardIndex];

        if (!cardActive || !cardOver) {
          return cards;
        }

        cardActive.columnId = cardOver.columnId;

        return arrayMove(cards, activeCardIndex, overCardIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === 'Column';

    // dropping a card over a column

    if (isActiveLoanApplicationCard && isOverAColumn) {
      setLoanApplicationCards((cards) => {
        const activeCardIndex = cards.findIndex((card) => card.id === activeId);
        const card = cards[activeCardIndex];

        if (!card) {
          return cards;
        }

        card.columnId = overId;
        return arrayMove(cards, activeCardIndex, activeCardIndex);
      });
    }
  };

  return (
    <div className={classes.container}>
      <DndContext sensors={sensors} onDragStart={onDragStart} onDragEnd={onDragEnd} onDragOver={onDragOver}>
        <div className={classes.columns}>
          {columnStates.map((col) => {
            return (
              <ColumnContainer
                loanCards={loanApplicationCards.filter((card) => card.columnId === col.id)}
                key={col.id}
                column={col}
              />
            );
          })}
        </div>
        {createPortal(
          <DragOverlay>
            {activeLoanApplicationCard && <LoanApplicationCard loanCard={activeLoanApplicationCard} />}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>
      {modalShown && (
        <Dialog open={modalShown} onClose={() => setModalShown(false)} aria-labelledby="form-dialog-title">
          <Dialog.Title>Confirmation</Dialog.Title>
          <Dialog.Text>
            Are you sure you would like to move this loan application to{' '}
            {transitionState ? vocab().loanState[transitionState].toLowerCase() : 'this'} state?
          </Dialog.Text>
          <Dialog.Actions>
            <Button onClick={() => cancelStateChange()} variant="outlined" size="small">
              {vocab().cancel}
            </Button>
            <Button onClick={() => confirmStateChange()} variant="contained" size="small">
              {vocab().confirm}
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </div>
  );
};
