import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import useStyles from './styles';
import adminVocab from '@/vocabulary';
import { ModalDialog } from '@/components/Dialog';
import { useRef } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import * as yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import { ErrorCode, passwordRegExp, phoneRegExp, useFetchWrapper } from '@nayla/common';
import { Select } from '@nayla/ui';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { IMutationOptions } from '@nayla/common/interfaces/mutation';

export interface IUserFormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
}

const rolesData = [
  {
    label: 'Admin',
    value: 'org_admin',
  },
  {
    label: 'Underwriter level one',
    value: 'org_underwriter_level_one',
  },
  {
    label: 'Underwriter level two',
    value: 'org_underwriter_level_two',
  },
  {
    label: 'Viewer',
    value: 'org_viewer',
  },
];

interface IAddMemberMutation {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  phone: string;
  password: string;
}

export const UserForm = ({ open, setOpen }: IUserFormProps) => {
  const formRef = useRef<IFormRef>(null);
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { mutate: addMember } = useAddMemberMutation({
    onSuccess: () => {
      setOpen(false);
      enqueueSnackbar(adminVocab.users.memberAddedSuccess, {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['organization', 'members'] });
    },
    onError: (error) => {
      const errorCode = typeof error === 'object' && error && 'errorCode' in error ? error.errorCode : null;

      if (errorCode === ErrorCode.FORBIDDEN) {
        enqueueSnackbar(adminVocab.errors.accessDenied, {
          variant: 'error',
        });
      } else if (errorCode === ErrorCode.USER_ALREADY_EXISTS) {
        enqueueSnackbar(adminVocab.users.userAlreadyExists, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(adminVocab.users.memberAddedError, {
          variant: 'error',
        });
      }
    },
  });

  return (
    <ModalDialog
      setOpen={setOpen}
      open={open}
      title={adminVocab.users.createUserTitle}
      onSubmit={() => formRef.current?.submit()}
    >
      <Form
        validationSchema={yup.object({
          email: yup.string().required().email(),
          firstName: yup.string().required(),
          lastName: yup.string().required(),
          role: yup.string().required({ message: 'Role is required' }),
          phone: yup.string().matches(phoneRegExp, adminVocab.errors.invalidPhone),
        })}
        ref={formRef}
        onSubmit={(member: IAddMemberMutation) => {
          console.log(member);
          addMember(member);
        }}
      >
        <div className={classes.container}>
          <Input
            autoComplete="email"
            label={adminVocab.users.properties.email}
            name="email"
            placeholder={adminVocab.placeholders.email}
          />
          <Input
            autoComplete="tel"
            label={adminVocab.users.properties.phone}
            name="phone"
            placeholder={adminVocab.placeholders.phone}
          />
          <Input
            autoComplete="given-name"
            label={adminVocab.users.properties.firstName}
            name="firstName"
            placeholder={adminVocab.placeholders.firstName}
          />
          <Input
            autoComplete="family-name"
            label={adminVocab.users.properties.lastName}
            name="lastName"
            placeholder={adminVocab.placeholders.lastName}
          />
          <Select
            name="role"
            label={adminVocab.users.properties.role}
            options={rolesData}
            placeholder={adminVocab.placeholders.select(adminVocab.roles.entityName)}
          />
        </div>
      </Form>
    </ModalDialog>
  );
};

const useAddMemberMutation = (options?: IMutationOptions) => {
  const fetcher = useFetchWrapper();

  return useMutation({
    mutationFn: async (member: IAddMemberMutation) => {
      return fetcher.post('/api/organizations/members', {
        body: JSON.stringify(member),
      });
    },
    ...options,
  });
};
