import { QueryClient } from '@tanstack/react-query';

export interface ICommonConfig {
  endpoints: any;
}

export class CommonConfig implements ICommonConfig {
  kratosPublicUrl: string = '';

  get endpoints() {
    return {
      path: {
        SIGNOUT: `/api/auth/signout`,
        AUTH_ENDPOINT: `/api/auth`,
        LOGIN: `/api/auth/login`,
        WHO_AM_I: `/api/auth/whoami`,
        FORGOT_PASSWORD: `/auth/forgot-password`,
        FORGOT_PASSWORD_CONFIRM: `/auth/confirm-forgot-password`,
        NEW_PASSWORD_CHALLENGE: `/auth/new-password-challenge`,
        CHANGE_PASSWORD: `/auth/change-password`,
        SIGNUP: `/api/auth/signup`,
        VERIFICATION: `/auth/verification`,
        OAUTH_LOGIN: `/auth/oauth/login`,
        USER_ENDPOINT: `/users`,
        ME_ENDPOINT: `/api/me`, // TODO: Check session
        API_USER_ENDPOINT: `/api/users`,
        LOAN_ENDPOINT: `/api/loans`,
        INSTALMENT_ENDPOINT: `/api/instalments`,
        LOAN_APPLICATION_ENDPOINT: `/api/loan-applications`,
        ROLE_ENDPOINT: `/roles`,
        DOCUMENT_ENDPOINT: `/api/documents`,
        VERIFY_TOKEN_ENDPOINT: `/auth/verification-token`,
        FORGOT_PASSWORD_TOKEN_CONFIRM: `/auth/confirm-forgot-password-token`,
        OB_CONNECTIONS: `/ob-connections`,
        LEAN_ENTITIES: `/api/lean-entities`,
        BUSINESSES_ENDPOINT: `/api/businesses`,
        LOAN_PRODUCT_ENDPOINT: `/api/loan-products`,
        OFFER_ENDPOINT: `/api/offers`,
        CONTRACT_ENDPOINT: `/api/contracts`,
        SETTINGS_ENDPOINT: `/api//settings`,
        PAYMENT_ENDPOINT: `/api/payments`,
        SEARCH_ENDPOINT: `/api/search`,
        API_AML_ENDPOINT: `/api/aml-profile`,
        LOOKUP_ENDPOINT: '/api/lookup',
      },
      method: {
        POST: 'POST',
        GET: 'GET',
        PUT: 'PUT',
        PATCH: 'PATCH',
        DELETE: 'DELETE',
      },
    };
  }
}

export const common = new CommonConfig();
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export * from './requests';
export * from './queries';
