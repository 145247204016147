import { ErrorCode } from '../enums';

export const useFetchWrapper = () => {
  function handleResponse(response: Response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        if (typeof data === 'object' && data && 'errorCode' in data && data.errorCode === ErrorCode.UNAUTHORIZED) {
          // redirect to auth
          window.location.href = import.meta.env.VITE_AUTH_URL
            ? `${import.meta.env.VITE_AUTH_URL}/employees/login`
            : '/auth/employees/login';
          return Promise.reject(data);
        }

        return Promise.reject(data);
      }

      return data;
    });
  }

  function request(method: string) {
    return (url: string, customConfig: RequestInit = {}) => {
      const config = {
        method,
        ...customConfig,
        headers: JSON.parse(
          // JSON used to override by undefined default values
          JSON.stringify({
            'Content-Type': 'application/json',
            ...customConfig.headers,
          }),
        ),
      };
      return fetch(url, config).then(handleResponse);
    };
  }

  function requestCSV() {
    return (url: string, customConfig: RequestInit = {}) => {
      const config = {
        method: 'GET',
        ...customConfig,
        headers: {
          Accept: 'text/csv',
          ...customConfig.headers,
        },
      };
      return fetch(url, config).then((response) => {
        return response.text();
      });
    };
  }

  return {
    get: request('GET'),
    post: request('POST'),
    patch: request('PATCH'),
    put: request('PUT'),
    delete: request('DELETE'),
    getCSV: requestCSV(),
  };
};
