import { IUser, IUserSnapshot } from './user';
import { AMLStatus, IBusiness, IBusinessSnapshot } from './business';
import { ILoanProduct, IRiskCategory } from './loan-product';
import { IOffer } from './offer';
import { INote } from './note';
import { IDocumentInfo } from './document';
import { YearlyRevenue } from '../enums';

export interface IAMLData {
  createdAt?: string;
  data?: {
    alert_decisions?: any[];
    customer_reference_id?: string;
    decisions_history?: any[];
    fname?: string;
    iden?: string;
    is_batch_query?: boolean;
    lname?: string;
    query_id?: string;
    query_status?: string;
    query_time?: string;
    query_type?: string;
    query_uuid?: string;
    result_time?: string;
    screen_result?: any[];
    source?: string;
    threshold?: number;
  };
  id?: string;
  isAutomated?: boolean;
  status?: AMLStatus;
}

export interface ILoanApplicationStateTransition {
  id: string;
  from: string;
  to: string;
  loan_application: string;
  triggered_by: string;
  transitioned_at: string;
}

export interface IRiskAssessment {
  id: string;
  maximumAmount: string;
  creditScore: number;
  comment: string;
  riskCategory: IRiskCategory;
  loanApplication: ILoanApplication;
  creditRiskAnalyst: IUser;
  rejected: boolean;
  createdAt: string;
  updatedAt: string;
  analystType: RiskAnalystType;
}

export interface INewRiskAssessment extends Partial<IRiskAssessment> {
  riskCategoryId?: string;
  rejected: boolean;
}

export enum RiskAnalystType {
  UNDERWRITER_LEVEL_1 = 'UNDERWRITER_LEVEL_1',
  UNDERWRITER_LEVEL_2 = 'UNDERWRITER_LEVEL_2',
}

export enum LoanApplicationState {
  SUBMIT = 'SUBMIT',
  UNDERWRITER_LEVEL_1 = 'UNDERWRITER_LEVEL_1',
  UNDERWRITER_LEVEL_2 = 'UNDERWRITER_LEVEL_2',
  CREATE_OFFER = 'CREATE_OFFER',
  ACCEPT_OFFER = 'ACCEPT_OFFER',
  COMMODITY_BUY = 'COMMODITY_BUY',
  AML = 'AML',
  CONTRACT = 'CONTRACT',
  COMMODITY_SELL = 'COMMODITY_SELL',
  IBAN = 'IBAN',
  PENDING_IVR = 'PENDING_IVR',
  IN_PROGRESS_IVR = 'IN_PROGRESS_IVR',
  PENDING_DISBURSE = 'PENDING_DISBURSE',
  SUCCESS_DISBURSE = 'SUCCESS_DISBURSE',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  DEFERRED = 'DEFERRED',
}

export enum DealStatus {
  CREATED = 'created',
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
}

export interface IRejectPayload {
  rejectionReason: string;
}

export interface IRejectPayload {
  rejectionReason: string;
}

export interface ILoanApplication {
  id: string;
  externalId: string;
  state: LoanApplicationState;
  eligibilityCheckSuccessful: boolean;
  amount: string;
  terms: number;
  purpose: string;
  documents: IDocumentInfo[];
  rejectionReason: string;
  deferReason: string;
  user: IUser;
  business: IBusiness;
  businessSnapshot: IBusinessSnapshot;
  userSnapshot: IUserSnapshot;
  loanProduct: ILoanProduct;
  offers: IOffer[];
  loanApplicationStateTransitions: ILoanApplicationStateTransition[];
  createdAt: string;
  updatedAt: string;
  notes: INote[];
  amlProfileUuid?: string;
  islamicDealTransactionKey?: string;
  islamicDealStatus?: DealStatus;
  rosomAccountId?: string;
  stateUpdatedAt?: string; // Add this field to track when the state was last updated
}

export interface ICreateUserSnapshot {
  firstName: string;
  lastName: string;
  profession: string;
  education: string;
}

export interface ICreateBusinessSnapshot {
  id: string;
  companyName: string;
  registrationDate: string;
  locationName: string;
  yearlyRevenue: YearlyRevenue;
  industry: string;
  employeeCount: string;
}

export interface INewLoanApplication {
  amount: string;
  terms: number;
  purpose: string;
  loanProductId: string;
  user: ICreateUserSnapshot;
  business: ICreateBusinessSnapshot;
  documentsIds: string[];
}
