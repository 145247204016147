import { ITab } from '@stryberventures/gaia-react.tabs/Tab';
import adminVocab from '@/vocabulary';
import { FC, useState } from 'react';
import useStyles from './styles';
import Tabs from '@stryberventures/gaia-react.tabs';
import FilesTab from '@/screens/LoanApplications/detail/tabs/risk-assessment/files';
import { LoanApplicationState } from '@nayla/common';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import CreditRiskAnalysisOneTab from '@/screens/LoanApplications/detail/tabs/risk-assessment/credit-risk-analysis-one';
import CreditRiskAnalysisTwoTab from '@/screens/LoanApplications/detail/tabs/risk-assessment/credit-risk-analysis-two';

const TAB_IDS = {
  CREDIT_RISK_ANALYSIS_ONE: 'credit_risk_analysis_one',
  CREDIT_RISK_ANALYSIS_TWO: 'credit_risk_analysis_two',
  FILES: 'files',
};

const TABS: ITab[] = [
  {
    active: false,
    disabled: false,
    id: TAB_IDS.CREDIT_RISK_ANALYSIS_ONE,
    label: adminVocab.loanApplication.tabs.creditRiskAnalysis_one,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.CREDIT_RISK_ANALYSIS_TWO,
    label: adminVocab.loanApplication.tabs.creditRiskAnalysis_two,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.FILES,
    label: adminVocab.loanApplication.tabs.files,
  },
];

const RiskAssessmentTab: FC = () => {
  const classes = useStyles();
  const data = useAtomValue(loanApplication);
  const [activeTab, setActiveTab] = useState(TAB_IDS.CREDIT_RISK_ANALYSIS_ONE);

  // Function to handle tab switching
  const switchTab = (tabId: string) => {
    setActiveTab(tabId);
  };
  if ([LoanApplicationState.SUBMIT].includes(data?.state as LoanApplicationState)) {
    return (
      <div className={classes.noContent}>
        The loan application has to be in the Underwriting state to access this tab.
      </div>
    );
  }

  const preliminaryAssessmentResult =
    data?.state === LoanApplicationState.UNDERWRITER_LEVEL_1 ||
    data?.state === LoanApplicationState.UNDERWRITER_LEVEL_2 ||
    data?.state === LoanApplicationState.COMMODITY_SELL ||
    data?.state === LoanApplicationState.CREATE_OFFER
      ? LoanApplicationState.SUBMIT
      : 'NA';

  return (
    <div className={classes.container}>
      <Tabs
        className={classes.tabs}
        onChange={(tabId: string) => switchTab(tabId)}
        size="small"
        tabs={TABS}
        variant="default"
      />
      <div className={classes.content}>
        {activeTab === TAB_IDS.CREDIT_RISK_ANALYSIS_ONE && (
          <CreditRiskAnalysisOneTab preliminaryAssessmentResult={preliminaryAssessmentResult} />
        )}
        {activeTab === TAB_IDS.CREDIT_RISK_ANALYSIS_TWO && (
          <CreditRiskAnalysisTwoTab preliminaryAssessmentResult={preliminaryAssessmentResult} />
        )}
        {activeTab === TAB_IDS.FILES && <FilesTab />}
      </div>
    </div>
  );
};

export default RiskAssessmentTab;
