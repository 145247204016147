import React from 'react';
import OffersTable from '@/components/OffersTable';
import { LoanApplicationState, useOffersQuery } from '@nayla/common';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';

const OffersTab = () => {
  const loanApp = useAtomValue(loanApplication);
  const { data: offers } = useOffersQuery(loanApp?.id);

  const disbursementPassed = loanApp?.state === LoanApplicationState.SUCCESS_DISBURSE;

  return (
    <OffersTable
      offers={offers}
      amlPassed={
        !disbursementPassed &&
        !!loanApp?.amlProfileUuid &&
        loanApp?.state !== LoanApplicationState.SUCCESS_DISBURSE &&
        loanApp?.state !== LoanApplicationState.REJECTED
      }
      loanApplicationId={loanApp?.id}
    />
  );
};

export default OffersTab;
