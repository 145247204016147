import withStyles from 'react-jss';
import styles from './styles';
import { Outlet } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from '../Header';
import { useMeUserQuery } from '@nayla/common';
import { useExtendSession } from '@/hooks/useExtendSession';

const Root = (props: any) => {
  useExtendSession();
  const { data, isLoading, error } = useMeUserQuery();
  const isAuthenticated = !!data;

  if (isLoading) {
    return null;
  }

  if (error || !data) {
    window.location.href = import.meta.env.VITE_AUTH_URL
      ? `${import.meta.env.VITE_AUTH_URL}/employees/login`
      : '/auth/employees/login';
    return null;
  }

  // const isAuthenticated = useAtomValue(isAuthenticatedState);
  return (
    <div className={props.classes.root}>
      {!isAuthenticated && <Header />}
      {isAuthenticated && <SideBar />}
      <Outlet />
    </div>
  );
};

export default withStyles(styles)(Root);
