export default {
  na: 'N/A',
  goMain: 'Go to main page',
  loading: 'Loading...',
  submit: 'Submit',
  continue: 'Continue',
  clear: 'Clear',
  downloadCSVFile: 'Download CSV File',
  alt: {
    logo: 'Logo',
  },
  sorting: {
    asc: 'Ascending',
    desc: 'Descending',
  },
  menuItems: {
    home: 'Home',
    users: 'Users',
    roles: 'Roles',
    account: 'Account',
    login: 'Login',
    signUp: 'Sign Up',
    homePage: 'Home',
    loanBook: 'Loan Book',
    loanApplications: 'Loan Applications',
    instalments: 'Instalments Schedule',
    userProfiles: 'User Profiles',
    businessProfiles: 'Business Profiles',
    admins: 'Admins',
    loanProducts: 'Loan Products',
    calculator: 'Loan Calculator',
    settings: 'Settings',
  },
  adminSettings: {
    title: 'Admin Settings',
    subtitle: 'Manage your admin settings here.',
    updateSettings: 'Update',
    entityName: 'Admin Settings',
    properties: {
      obConnectionTimeframeMinutes: 'Open Banking Connection Timeframe (minutes)',
      obConnectionRetryIntervalMinutes: 'Open Banking Connection Retry Interval (minutes)',
      expiryTimeframeMinutes: 'Expiry Timeframe (minutes)',
      instalmentCheckIntervalMilliseconds: 'Instalment Check Interval (milliseconds)',
      temporaryRejectionDays: 'Temporary Rejection Days',
      instalmentOverdueDays: 'Instalment Overdue Days',
      instalmentDelayedDays: 'Instalment Delayed Days',
      instalmentUnsettledDays: 'Instalment Unsettled Days',
      loanTier1DelayDays: 'Loan Tier 1 Delay Days',
      loanTier2DelayDays: 'Loan Tier 2 Delay Days',
      loanTier3DelayDays: 'Loan Tier 3 Delay Days',
      loanDefaultedDays: 'Loan Defaulted Days',
      contractTemplateCode: 'Contract Template Code',
    },
  },
  errors: {
    oops: 'Oops!',
    unexpected: 'Sorry, an unexpected error has occurred.',
    mustBeNumber: 'Must be a number',
    positiveNumber: 'Must be a positive number',
    invalidPhone: 'Invalid phone number',
    invalidIban: 'Invalid Iban',
    verificationError: 'Verification has failed',
    signUpError: 'Sign Up has failed',
    oopsSomethingWentWrong: 'Oops! Something is wrong, please contact the administrator.',
    minLessThanMaxAmount: 'Min Loan Amount should be less than Max Loan Amount',
    minLessThanMaxTerm: 'Min Loan Term should be less than Max Loan Term',
    accessDenied: 'Forbidden - access is denied',
    required: (fieldName: string) => `${fieldName} is required`,
    min: (fieldName: string, min: number) => `${fieldName} should be greater than ${min}`,
    max: (fieldName: string, max: number) => `${fieldName} should be less than ${max}`,
  },
  authentication: {
    login: 'Login',
    signUp: 'Sign Up',
    verifyEmail: 'Verify Email',
    code: 'Enter the code',
    or: 'OR',
    forgotPassword: 'Forgot Password',
    forgotPasswordLabel: 'Forgot your password?',
    changePassword: 'Change Password',
    verifyCode: 'Verify Code',
    signOut: 'Sign Out',
    googleSignIn: 'Login with Google',
    googleSignUp: 'Sign Up with Google',
    passwordHint: 'The password should have 8 characters, lower and upper case, numbers and special characters.',
    repeatPassword: 'Repeat Password',
    newPassword: 'New Password',
    passwordsShouldNotMatch: 'New password should not match the old password',
    passwordsDoNotMatch: 'Passwords do not match',
    getCode: 'Get code!',
    emailVerified: 'Email verified!',
    codeSent: 'Code sent!',
    passwordChanged: 'Password changed!',
    previousPasswordInvalid: 'Please enter a valid previous password',
    validationMessage:
      'Password must contain at least 8 characters, 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character',
    temporaryPasswordMustBeUsed: 'A temporary password must be used to trigger a mandatory change password request',
  },
  userProfiles: {
    title: 'User Profile',
    description: 'Manage user profiles here.',
    type: 'User',
    properties: {
      name: 'Name',
      ID: 'ID',
      totalBorrowedSAR: 'Total Borrowed SAR',
      clientSince: 'Client Since',
      riskCategory: 'Risk Category',
      activeLoans: 'Active Loans',
      type: 'Type',
    },
    tabs: {
      kyc: 'KYC',
      aml: 'AML',
      loanHistory: 'Loan History',
    },
  },
  businessProfiles: {
    title: 'Business Profile',
    description: 'Manage business profiles here.',
    type: 'Business',
    properties: {
      name: 'Name',
      CR: 'CR',
      totalBorrowedSAR: 'Total Borrowed SAR',
      clientSince: 'Client Since',
      riskCategory: 'Risk Category',
      activeLoans: 'Active Loans',
      type: 'Type',
    },
    tabs: {
      kyb: 'KYB',
      aml: 'AML',
      loanHistory: 'Loan History',
    },
  },
  clientProfilesTable: {
    headCells: {
      name: 'Name',
      id: 'CR / ID',
      createdAt: 'Created At',
    },
  },
  users: {
    memberAddedSuccess: 'Member added successfully',
    memberAddedError: 'Failed to add member',
    userAlreadyExists: 'User already exists',
    entityName: 'User',
    userInfoTitle: 'Personal Info',
    loading: 'Loading users...',
    admins: 'Admins',
    tableTitle: 'Users',
    tableDescription: 'Manage your team and preferences here.',
    createUserTitle: 'Create User',
    editUserTitle: 'Edit User',
    deleteUserTitle: 'Delete User',
    deleteUserDescription: 'Are you sure you want to delete this user?',
    changePassword: 'Change Password?',
    typePassword: 'Type your password here',
    rememberMe: 'Remember me',
    properties: {
      username: 'Username',
      email: 'Email',
      enabled: 'Status',
      roles: 'Roles',
      role: 'Role',
      phone: 'Phone',
      firstName: 'First Name',
      lastName: 'Last Name',
      password: 'Password',
      code: 'Code',
      education: 'Education level',
      profession: 'Profession',
      name: 'Name',
      birthdate: 'Birthdate',
      idNumber: 'ID Number',
      isPep:
        'Are you or any of your immediate family members considered a politically exposed person (PEP)? (A PEP is someone who holds or has held a prominent public position or role in a government or international organization.)',
    },
    statuses: {
      FORCE_CHANGE_PASSWORD: 'Force Change Password',
      CONFIRMED: 'Confirmed',
      UNCONFIRMED: 'Unconfirmed',
      ARCHIVED: 'Archived',
      COMPROMISED: 'Compromised',
      UNKNOWN: 'Unknown',
      RESET_REQUIRED: 'Reset Required',
    },
    actions: {
      add: 'Add User',
      edit: 'Edit User',
      delete: 'Delete User',
    },
    enabled: {
      true: 'Active',
      false: 'Inactive',
    },
  },
  loans: {
    title: 'Loan Book',
    tableDescription: 'Active Loans',
    originalAmount: 'Original Amount SAR',
    remainingBalance: 'Remaining Balance SAR',
    start: 'Start',
    term: 'Loan Term',
    nextPayment: 'Next Payment',
    remainingInstallments: 'Remaining Installments',
    status: 'Status',
    filtersTitle: 'Filter Search',
    tabs: {
      instalments: 'Instalments Status',
      applicationInfo: 'Application Info',
      logs: 'Notes',
    },
    states: {
      ACTIVE: 'Active',
      DELAYED: 'Delayed',
      SETTLED: 'Settled',
      ACTIVE_WITH_DELAY: 'Active with delay',
      SETTLED_WITH_DELAY: 'Settled with delay',
      DEFAULTED: 'Defaulted',
    },
    filters: {
      startDate: 'By Start Date',
      status: 'By Status',
      from: 'From',
      to: 'To',
    },
    properties: {
      businessName: 'Business Name',
      smeName: 'SME Name',
      businessCR: 'Business CR',
      cr: 'CR',
      loanAmount: 'Loan Amount SAR',
      loanType: 'LoanType',
      loanId: 'Loan ID',
      distributionPartner: 'Distribution Partner',
      dp: 'DP',
      startDate: 'Start date',
      client: 'Client',
      nextPaymentDay: 'Next Payment Day',
      paymentStatus: 'Payment Status',
      debtProvider: 'Debt Provider',
      state: 'Loan Status',
    },
  },
  search: {
    title: 'Search Results',
    clientBusiness: 'Business Profiles',
    clientUser: 'User Profiles',
    loanApplication: 'Loan Application',
    loans: 'Loans',
    searchExampleTitle: 'Examples on what to search....',
    searchExample1: 'Loan ID Number   LID76383',
    searchExample2: 'Application ID Number  AID865568734',
    searchExample3: 'CR Number CR576543467765',
    searchExample4: 'ID Number    ID4654689976755',
    searchExample5: 'Persons name    Mohamed Guilbert ',
    searchExample6: 'Business Name   Oasis Travel Inc',
    table: {
      businesses: {
        cr: 'CR',
        crEntityNumber: 'CR Entity Number',
        companyName: 'Company Name',
        dateFounded: 'Date Founded',
        ownerFirstName: 'Owner Name',
        ownerLastName: 'Owner Last Name',
      },
      users: {
        email: 'Email',
        education: 'Education',
        profession: 'Profession',
        firstName: 'First Name',
        lastName: 'Last Name',
        idNumber: 'ID Number',
        phone: 'Phone',
        birthdate: 'Birthdate',
      },
      loanApplications: {
        id: 'Application ID',
        loanAmountRequest: 'Loan Amount Request',
        termRequest: 'Term Request',
        companyCR: 'Company CR',
        status: 'State',
      },
      loans: {
        id: 'Loan ID',
        state: 'State',
        monthlyInstalment: 'Monthly Instalment',
        startDate: 'Start Date',
      },
    },
  },
  instalments: {
    tableTitle: 'Instalments Schedule',
    tableDescription: 'List of all instalments',
    filtersTitle: 'Filter Search',
    filters: {
      startDate: 'By Due Date',
      distributionPartner: 'By Distribution Partner',
      productType: 'By Product Type',
      status: 'By Status',
      from: 'From',
      to: 'To',
    },
    properties: {
      id: 'Transaction ID',
      loanId: 'Loan ID',
      dueDate: 'Due Date',
      startDate: 'Start Date',
      receivedAmount: 'Received Amount',
      state: 'State',
    },
  },
  loanApplication: {
    title: 'Loan Applications',
    detailTitle: 'Loan Application Detail',
    filtersTitle: 'Filter Search',
    description: 'Application Stages',
    applicationID: 'Application ID',
    applicationDate: 'Application Date',
    loanAmountRequest: 'Loan Amount Request',
    termRequest: 'Term Request',
    monthsLabel: 'month(s)',
    companyCR: 'Company CR',
    rejectApplication: 'Reject Application',
    confirmRejection: 'Confirm Rejection',
    rejectDescription: 'Please confirm the rejection of this application',
    acceptApplication: 'Accept Application',
    confirmAcceptance: 'Confirm Move To Underwrite',
    acceptDescription: 'Please confirm Move Application To Underwrite Stage',
    deferApplication: 'Defer Application',
    confirmDeferral: 'Confirm Deferral',
    deferDescription: 'Please confirm the deferral of this application',
    status: 'Status',
    notContractState: 'The loan application needs to pass risk assessment to access this tab.',
    loanDisbursedSuccess: 'The loan has been successfully disbursed, and this tab is not available anymore.',
    noInfoSubmitted: 'No information submitted',
    noRiskAssessment: 'No risk assessment submitted',
    payment: {
      bankAccountNotProvided: 'The user has not provided the bank account details yet.',
      disburseEligibility: 'Make sure that the user provided the IBAN, payment information and has signed a contract.',
      none: 'None',
      provided: 'Provided by the client',
      notProvidedDescription: 'The Client has not inserted yet any payment info.',
      hyperPayProvidedDescription: 'The Client has provided their payment info, via Hyperpay.',
      creditCardProvidedDescription: 'The card / debit card has been confirmed.',
      noOfferSelected: 'There is no offer selected. Please go to the Offers Tab, and select an offer to disburse.',
      companyInformation: 'Company Information',
      companyName: 'Company Name',
      iban: 'IBAN',
      bankName: 'Bank Name',
      disbursementState: 'Disbursement Status',
      disburseLoan: 'Disburse Loan',
      createAccount: 'Create Payment account',
      commodityDealNotConfirmed: 'Commodity deal not confirmed yet',
      disbursementStates: {
        PENDING: 'Pending',
        SUCCESSFUL: 'Success',
        FAILED: 'Failed',
      },
    },
    properties: {
      amount: 'Amount',
      terms: 'Terms',
      adminFees: 'Admin Fees',
      state: 'State',
      loanProductIds: 'Loan Products',
    },
    filters: {
      startDate: 'By Start Date',
      from: 'From',
      to: 'To',
      status: 'By Status',
    },
    calculator: {
      totalRepayableAmount: 'Total Repayable Amount',
      costOfFinancing: 'Cost of Financing',
      totalProfitFees: 'Total Profit Fees',
      totalPrinciplePlusProfit: 'Total Principle + Profit',
      numberOfInstalments: 'Number of Instalments',
      monthlyInstalment: 'Monthly Instalment',
      totalAmountDisbursed: 'Total Amount Disbursed',
      totalAdminFees: 'Total Admin Fees',
    },
    contract: {
      contractSentOn: 'Contract Sent On',
      contractLink: 'Contract Link',
      state: 'Status',
      noContractsReceived: 'User did not receive any contracts',
      noOfferSelected:
        "There's no offer selected. Please go to the Offers Tab, and select an offer to create a contract.",
      sendNewContract: 'Send Contract',
      states: {
        EJECT: 'Eject',
        CLOSED: 'Closed',
        NO_RESPONSE: 'No Response',
        PARTIALLY_SIGNED: 'Partially Signed',
        SIGNED: 'Signed',
      },
    },
    offer: {
      entityName: 'Offer',
      sendOffer: 'Send Offer',
      scrollDown: '*Scroll down to see payment Schedule',
      runAMLMessage: 'Please first run AML checks to be able to send a contract to the user.',
      noOffers: 'User did not receive any offers.',
      states: {
        PENDING: 'Pending',
        ACCEPTED: 'Accepted',
        REJECTED: 'Rejected',
      },
      properties: {
        aprRate: 'Annual Percentage Rate (APR)',
        monthlyRate: 'Monthly Rate',
        adminFees: 'Admin fees (%)',
        amount: 'Amount (SAR)',
        terms: 'Terms (months)',
      },
    },
    riskAssessment: {
      entityName: 'Risk Assessment',
      assessmentOutcome: 'Preliminary Assessment outcome',
      submit: 'Submit',
      confirmTitle: 'Submit Credit  Analysis',
      confirmDescription:
        'Are you sure you want to confirm this information? This will have an impact calculating the offer for the client. ',
      properties: {
        creditScore: 'Credit Score',
        riskCategory: 'Credit Category',
        comments: 'Comments',
        maxLoanAmount: 'Maximum Loan Financing',
      },
    },
    tabs: {
      submission: 'Submission',
      riskAssessment: 'Risk Assessment',
      contractNegotiation: 'Contract Negotiation',
      disbursement: 'Disbursement',
      logs: 'Notes',
      application: 'Application',
      documents: 'Documents',
      openBanking: 'Open Banking',
      kyc: 'KYC',
      kyb: 'KYB',
      creditRiskAnalysis_one: 'Level 1 Underwriting',
      creditRiskAnalysis_two: 'Level 2 Underwriting',
      files: 'Files',
      calculator: 'Calculator',
      offers: 'Offers',
      aml: 'AML',
      contract: 'Contract',
      payment: 'Payment',
    },
    columnStates: {
      SUBMISSIONS: 'Submissions',
      FINAL_RISK_ASSESSMENT: 'Final Risk Assessment',
      CONTRACT_NEGOTIATION: 'Contract Negotiation',
      DISBURSEMENT: 'Disbursement',
    },
    aml: {
      amlRunSuccess: 'AML successfully run',
      amlRunFailed: 'AML run failed',
      amlRefreshSuccess: 'AML successfully refreshed',
      amlRefreshFailed: 'AML refresh failed',
      runAML: 'Run AML',
      refreshAML: 'Refresh Results',
      clientResults: 'Client AML Results',
      companyResults: 'Company AML Results',
      noAmlProfile: 'No AML profile found for this client',
    },
    amlStatuses: {
      APPROVED: 'AML check passed',
      REJECTED: 'AML check failed. Customer must be off-boarded',
      ACTION_REQUIRED: 'Requires manual check on Focal Portal',
      NONE: 'None',
    },
    states: {
      SUBMIT: 'Submitted',
      UNDERWRITER_LEVEL_1: 'Underwriter Level 1',
      UNDERWRITER_LEVEL_2: 'Underwriter Level 2',
      CREATE_OFFER: 'Create Offer',
      ACCEPT_OFFER: 'Pending Customer Approval',
      COMMODITY_BUY: 'Commodity Buy',
      AML: 'AML Check',
      CONTRACT: 'Contract',
      COMMODITY_SELL: 'Commodity Sell',
      IBAN: 'IBAN Verification',
      PENDING_IVR: 'Pending IVR',
      IN_PROGRESS_IVR: 'In Progress IVR',
      PENDING_DISBURSE: 'Pending Disburse',
      SUCCESS_DISBURSE: 'Success Disburse',
      REJECTED: 'Rejected',
      EXPIRED: 'Expired',
      DEFERRED: 'Deferred',
    },

    transitions: {
      signed: 'Loan Application moved to Signed state',
      underwriting: 'Loan Application moved to Underwriting state',
      approved: 'Loan Application moved to Approved state',
      deferred: 'Loan Application moved to Deferred state',
      disbursed: 'Loan Application has been disbursed',
    },
  },
  businesses: {
    businessInfoTitle: 'Business Info',
    properties: {
      name: 'Business Name',
      dateFounded: 'Date Founded',
      industry: 'Industry',
      location: 'Location',
      yearlyRevenue: 'Yearly Revenue',
      purpose: 'Reason for the loan',
      entityNumber: 'Entity Number',
      issueDate: 'Issue Date',
      expiryDate: 'Expiry Date',
      businessType: 'Business Type',
      employeeCount: 'Employee Count',
      status: 'Status',
      website: 'Website',
      zipCode: 'Zip Code',
      fax: 'Fax',
      telephone1: 'Telephone 1',
      telephone2: 'Telephone 2',
      paidAmount: 'Paid Amount',
    },
  },
  roles: {
    entityName: 'Role',
    loading: 'Loading roles...',
    tableTitle: 'Roles',
    tableDescription: "Manage your team's roles and preferences here.",
    createRoleTitle: 'Create Role',
    editRoleTitle: 'Edit Role',
    deleteRoleTitle: 'Delete Role',
    deleteRoleDescription: 'Are you sure you want to delete this role?',
    properties: {
      rolename: 'Role Name',
      description: 'Description',
      users: 'Users',
    },
  },
  loanProducts: {
    entityName: 'Loan Product',
    detailTitle: 'Loan Product Detail',
    description: 'Products offered',
    createTitle: 'Create new loan Product',
    editTitle: 'Edit Loan Product',
    tabs: {
      details: 'Details',
    },
    properties: {
      name: 'Name',
      description: 'Description',
      priceCents: 'Price',
      quantity: 'Quantity',
      adminFees: 'Admin Fees',
      numCategories: 'Number of Risk Categories',
      minLoanAmount: 'Min Loan Amount',
      maxLoanAmount: 'Max Loan Amount',
      minTerms: 'Min Terms',
      maxTerms: 'Max Terms',
    },
    tableTitle: 'Loan Products',
    loading: 'Loading loan products...',
    editProductTitle: 'Edit product',
    createProductTitle: 'Create product',
  },
  placeholders: {
    adminFees: '3.00',
    email: 'max-mustermann@gmail.com',
    username: 'max-mustermann',
    name: 'name',
    firstName: 'Max',
    lastName: 'Mustermann',
    description: 'description',
    phone: '+49 123 456789',
    password: 'Password',
    code: '123456',
    role: 'admin',
    roleDescription: 'Role that has access to everything',
    select: (objectName: string) => `Select ${objectName}`,
    price: '0',
    quantity: '0',
    loanProductName: 'Loan Product',
    minLoanAmount: '2.00',
    maxLoanAmount: '3.00',
    minTerms: '3',
    maxTerms: '12',
    comments: 'Insert comments',
    manualRejectionReason: 'Manually rejected by admin',
  },
  table: {
    actions: {
      add: 'Add',
      edit: 'Edit',
      delete: 'Delete',
      enable: 'Enable',
      disable: 'Disable',
      filters: 'Filters',
    },
    noData: 'No data found',
  },
  toasts: {
    success: {
      created: (objectName: string) => `${objectName} created`,
      updated: (objectName: string) => `${objectName} updated`,
      deleted: (objectName: string) => `${objectName} deleted`,
      enabled: (objectName: string) => `${objectName} enabled`,
      disabled: (objectName: string) => `${objectName} disabled`,
      passwordChanged: 'Password changed. You can now login with your new password',
      verified: 'User verified',
      verificationEmailSent: 'A verification email has been sent. Please check your inbox.',
    },
    errors: {
      create: (objectName: string) => `Error creating ${objectName}`,
      update: (objectName: string) => `Error updating ${objectName}`,
      delete: (objectName: string) => `Error deleting ${objectName}`,
      enable: (objectName: string) => `Error enabling ${objectName}`,
      disable: (objectName: string) => `Error disabling ${objectName}`,
      fetchError: (objectName: string) => `Error fetching ${objectName}`,
      sessionExpired: 'Session expired, please login again',
      passwordChangedError: 'Error changing password',
    },
  },
};
