import useStyles from './styles';
import { LoanApplicationCardType } from '@/components/LoanApplicationBoard/types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Tag from '@stryberventures/gaia-react.tag';
import { getTagColorByLoanApplicationState } from '@nayla/common';

interface ILoanApplicationCardProps {
  loanCard: LoanApplicationCardType;
}

export const LoanApplicationCard = (props: ILoanApplicationCardProps) => {
  const { loanCard } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { setNodeRef, isDragging, attributes, listeners, transform, transition } = useSortable({
    id: loanCard.id,
    data: {
      type: 'Card',
      loanCard: loanCard,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <div className={classNames(classes.loanCard, classes.loanCardDragging)}>
        <div className={classes.companyName}>{loanCard.companyName}</div>
        <div className={classes.loanId}>{loanCard.loanAppId}</div>
      </div>
    );
  }

  return (
    <div
      onClick={() => {
        navigate(`/loan-application/${loanCard.id}`);
      }}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={classes.loanCard}
    >
      <div className={classes.loanInfo}>
        <div className={classes.companyName}>{loanCard.companyName}</div>
        <div className={classes.loanId}>{loanCard.loanAppId}</div>
      </div>
      {loanCard.state && (
        <Tag className={classes.tag} color={getTagColorByLoanApplicationState(loanCard.state)}>
          {loanCard.stateLabel}
        </Tag>
      )}
    </div>
  );
};
