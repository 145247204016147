import * as React from 'react';
import adminVocab from '@/vocabulary';
import { UserForm } from '@/components/UserForm';
import useStyles from './styles';
import Table, { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@stryberventures/gaia-react.table';
import Button from '@stryberventures/gaia-react.button';
import { CountIcon } from '@stryberventures/gaia-react.icons';
import { IUser, UserType } from '@nayla/common';
import theme from '@nayla/ui/styles';
import { EnhancedTableHead, IHeadCell } from '@nayla/ui/components/TableHead';

interface IUsersTableProps {
  rows: IUser[];
}

const headCells: readonly IHeadCell[] = [
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: adminVocab.users.properties.email,
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: adminVocab.users.properties.phone,
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: adminVocab.users.properties.role,
  },
];

const mapRoleToLabel: Record<UserType, string> = {
  [UserType.CUSTOMER]: 'Customer',
  [UserType.ORG_ADMIN]: 'Admin',
  [UserType.ORG_UNDERWRITER_LEVEL_ONE]: 'Underwriter Level One',
  [UserType.ORG_UNDERWRITER_LEVEL_TWO]: 'Underwriter Level Two',
  [UserType.ORG_VIEWER]: 'Viewer',
};

export const UsersTable = ({ rows }: IUsersTableProps) => {
  const classes = useStyles();
  const [userModalShown, setUserModalShown] = React.useState(false);

  return (
    <>
      <div className={classes.header}>
        <Button
          className={classes.createItem}
          onClick={() => setUserModalShown(true)}
          iconLeft={() => <CountIcon fill={theme.colors.primary.main500} variant="plus" />}
          type="button"
          variant="outlined"
          shape="round"
        >
          {adminVocab.table.actions.add}
        </Button>
      </div>
      <TableContainer>
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id}>{headCell.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow className={classes.tableRow} role="checkbox" tabIndex={-1} key={row.id}>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{mapRoleToLabel[row.type]}</TableCell>
                </TableRow>
              );
            })}
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>
                  <p style={{ textAlign: 'center' }}>{adminVocab.table.noData}</p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {userModalShown && <UserForm open={userModalShown} setOpen={setUserModalShown} />}
    </>
  );
};
