import { FC, useEffect, useMemo, useState } from 'react';
import Layout from '@nayla/ui/components/Layout';
import adminVocab from '@/vocabulary';
import { useNavigate, useParams } from 'react-router-dom';
import {
  formatDate,
  getTagColorByLoanApplicationState,
  getTagColorByLoanState,
  IBusiness,
  ILoan,
  ILoanApplication,
  IUser,
  numberWithCommas,
  useSearchQuery,
} from '@nayla/common';
import Tabs from '@stryberventures/gaia-react.tabs';
import useStyles from './styles';
import { ITab } from '@stryberventures/gaia-react.tabs/Tab';
import Table, { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@stryberventures/gaia-react.table';
import Tag from '@stryberventures/gaia-react.tag';

interface ISearchTabs {
  BUSINESSES: string;
  USERS: string;
  LOAN_APPLICATIONS: string;
  LOANS: string;
}

const Search: FC = () => {
  const { text } = useParams<{ text: string }>();
  const { data } = useSearchQuery(text || '');
  const classes = useStyles();
  const navigate = useNavigate();

  const TAB_IDS: ISearchTabs = useMemo(() => {
    return {
      BUSINESSES: 'businesses',
      USERS: 'users',
      LOAN_APPLICATIONS: 'loanApplications',
      LOANS: 'loans',
    };
  }, []);

  const resultToTabId = (result?: 'loanApplication' | 'loan' | 'business' | 'user') => {
    switch (result) {
      case 'loanApplication':
        return TAB_IDS.LOAN_APPLICATIONS;
      case 'loan':
        return TAB_IDS.LOANS;
      case 'business':
        return TAB_IDS.BUSINESSES;
      case 'user':
        return TAB_IDS.USERS;
      default:
        return TAB_IDS.BUSINESSES;
    }
  };

  const TABLE_HEAD_LABELS = {
    [TAB_IDS.LOANS]: [
      adminVocab.search.table.loans.id,
      adminVocab.search.table.loans.state,
      adminVocab.search.table.loans.monthlyInstalment,
      adminVocab.search.table.loans.startDate,
    ],
    [TAB_IDS.BUSINESSES]: [
      adminVocab.search.table.businesses.companyName,
      adminVocab.search.table.businesses.cr,
      adminVocab.search.table.businesses.crEntityNumber,
      adminVocab.search.table.businesses.dateFounded,
      adminVocab.search.table.businesses.ownerFirstName,
      adminVocab.search.table.businesses.ownerLastName,
    ],
    [TAB_IDS.USERS]: [
      adminVocab.search.table.users.email,
      adminVocab.search.table.users.firstName,
      adminVocab.search.table.users.lastName,
      adminVocab.search.table.users.profession,
      adminVocab.search.table.users.birthdate,
      adminVocab.search.table.users.phone,
    ],
    [TAB_IDS.LOAN_APPLICATIONS]: [
      adminVocab.search.table.loanApplications.id,
      adminVocab.search.table.loanApplications.loanAmountRequest,
      adminVocab.search.table.loanApplications.termRequest,
      adminVocab.search.table.loanApplications.companyCR,
      adminVocab.search.table.loanApplications.status,
    ],
  };
  const TABS_INITIAL_DETAIL: ITab[] = [
    {
      active: true,
      disabled: false,
      id: TAB_IDS.BUSINESSES,
      label: adminVocab.search.clientBusiness,
    },
    {
      active: true,
      disabled: false,
      id: TAB_IDS.USERS,
      label: adminVocab.search.clientUser,
    },
    {
      active: false,
      disabled: false,
      id: TAB_IDS.LOAN_APPLICATIONS,
      label: adminVocab.search.loanApplication,
    },
    {
      active: false,
      disabled: false,
      id: TAB_IDS.LOANS,
      label: adminVocab.search.loans,
    },
  ];
  const [activeTab, setActiveTab] = useState<string>(resultToTabId(data?.result));
  const [tabs, setTabs] = useState<ITab[]>(TABS_INITIAL_DETAIL);

  useEffect(() => {
    const updatedTabs = tabs.map((tab: ITab) => ({
      ...tab,
      active: tab.id === activeTab,
    }));
    setTabs(updatedTabs);
  }, [activeTab, tabs]);

  useEffect(() => {
    const filteredTabs: string[] = [];
    if (!data?.loans.length) {
      filteredTabs.push(TAB_IDS.LOANS);
    }
    if (!data?.loanApplications.length) {
      filteredTabs.push(TAB_IDS.LOAN_APPLICATIONS);
    }
    if (!data?.businesses.length) {
      filteredTabs.push(TAB_IDS.BUSINESSES);
    }
    if (!data?.users.length) {
      filteredTabs.push(TAB_IDS.USERS);
    }
    // make tabs disabled if there is no data

    const updatedTabs = tabs.map((tab: ITab) => ({
      ...tab,
      disabled: filteredTabs.includes(tab.id),
      active: tab.id === activeTab,
    }));
    setTabs(updatedTabs);
  }, [data, tabs, activeTab, TAB_IDS]);

  const getTableBody = (actualActiveTab: string) => {
    switch (actualActiveTab) {
      case TAB_IDS.LOANS:
        return data?.loans.map((loan: ILoan) => (
          <TableRow key={TAB_IDS.LOANS} onClick={() => loan.id && navigate(`/loan/${loan.id}`)}>
            <TableCell>{loan?.externalId}</TableCell>
            <TableCell>
              <Tag color={getTagColorByLoanState(loan?.state)}>{adminVocab.loans.states[loan.state]}</Tag>
            </TableCell>
            <TableCell>{numberWithCommas(loan?.monthlyInstalment)} SAR</TableCell>
            <TableCell>{formatDate(loan?.createdAt)}</TableCell>
          </TableRow>
        ));
      case TAB_IDS.LOAN_APPLICATIONS:
        return data?.loanApplications.map((loanApplication: ILoanApplication) => (
          <TableRow
            key={TAB_IDS.LOAN_APPLICATIONS}
            onClick={() => loanApplication.id && navigate(`/loan-application/${loanApplication.id}`)}
          >
            <TableCell>{loanApplication?.externalId}</TableCell>
            <TableCell>{numberWithCommas(loanApplication?.amount)} SAR</TableCell>
            <TableCell>{loanApplication?.terms}</TableCell>
            <TableCell>{loanApplication?.business?.commercialRegistrationNumber}</TableCell>
            <TableCell>
              <Tag color={getTagColorByLoanApplicationState(loanApplication.state)}>
                {adminVocab.loanApplication.states[loanApplication.state]}
              </Tag>
            </TableCell>
          </TableRow>
        ));
      case TAB_IDS.BUSINESSES:
        return data?.businesses.map((business: IBusiness) => (
          <TableRow
            key={TAB_IDS.BUSINESSES}
            onClick={() => business.id && navigate(`/business-profile/${business.id}`)}
          >
            <TableCell>{business?.companyName}</TableCell>
            <TableCell>{business?.commercialRegistrationNumber}</TableCell>
            <TableCell>{business?.crEntityNumber}</TableCell>
            <TableCell>{formatDate(business.registrationDate)}</TableCell>
            <TableCell>{business?.owner?.firstName}</TableCell>
            <TableCell>{business?.owner?.lastName}</TableCell>
          </TableRow>
        ));
      case TAB_IDS.USERS:
        return data?.users.map((user: IUser) => (
          <TableRow key={TAB_IDS.USERS} onClick={() => user.id && navigate(`/user-profile/${user.id}`)}>
            <TableCell>{user?.email}</TableCell>
            <TableCell>{user?.firstName}</TableCell>
            <TableCell>{user?.lastName}</TableCell>
            <TableCell>{user?.profession}</TableCell>
            <TableCell>{formatDate(user?.birthdate)}</TableCell>
            <TableCell>{user?.phone}</TableCell>
          </TableRow>
        ));
    }
  };

  return (
    <Layout title={adminVocab.search.title} subtitle={''}>
      <div className={classes.container}>
        <Tabs
          className={classes.tabs}
          onChange={(tabId: string) => setActiveTab(tabId)}
          size="small"
          tabs={tabs}
          variant="default"
        />
        <div className={classes.table}>
          <TableContainer key={activeTab}>
            <Table>
              <TableHead>
                <TableRow>
                  {(TABLE_HEAD_LABELS[activeTab] ?? []).map((value: string, index: number) => (
                    <TableCell key={index}>{value}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{getTableBody(activeTab)}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </Layout>
  );
};

export default Search;
