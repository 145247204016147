import {
  IFetchRequest,
  ILoanApplication,
  IRequestCalculator,
  INewLoanApplication,
  INewRiskAssessment,
  IRiskAssessment,
  IOffer,
  INewOffer,
  IOracleDocumentInfo,
  RiskAnalystType,
} from '../../interfaces';
import { common } from '../index';

export const getLoanApplication = (
  id: string | undefined,
  { fetcher, options, expand }: IFetchRequest,
): Promise<ILoanApplication> => {
  if (!id) throw new Error('id is required');

  let queryString = '';
  if (expand && expand.length > 0) {
    queryString = expand.map((expandParam) => `expand[]=${expandParam}`).join('&');
  }

  return fetcher.get(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}?${queryString}`, options);
};

export const getLoanApplicationDocumentLinks = (
  id: string | undefined,
  { fetcher, options }: IFetchRequest,
  fileNames: string[] = [],
): Promise<IOracleDocumentInfo[]> => {
  if (!id) throw new Error('id is required');

  let queryString = '';
  if (fileNames && fileNames.length > 0) {
    queryString = fileNames.map((fileName) => `fileNames[]=${fileName}`).join('&');
  }

  return fetcher.get(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/documents-par?${queryString}`, options);
};

export const getLoanApplications = ({
  fetcher,
  options,
  expand,
  query,
}: IFetchRequest): Promise<ILoanApplication[]> => {
  let queryString = '';
  if (expand && expand.length > 0) {
    queryString += expand.map((expandParam) => `expand[]=${expandParam}`).join('&');
  }
  if (query) {
    queryString += query.length === 0 ? query : '&' + query;
  }

  return fetcher.get(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}?${queryString}`, options);
};

export const addLoanApplication = (
  loanApplication: INewLoanApplication,
  { fetcher, options }: IFetchRequest,
): Promise<ILoanApplication> => {
  return fetcher.post(common.endpoints.path.LOAN_APPLICATION_ENDPOINT, {
    body: JSON.stringify(loanApplication),
    ...options,
  });
};

export const getRiskAssessments = (
  id: string | undefined,
  { fetcher, options }: IFetchRequest,
): Promise<IRiskAssessment[]> => {
  if (!id) throw new Error('id is required');
  return fetcher.get(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/risk-assessments`, options);
};

export const addRiskAssessment = (
  id: string | undefined,
  riskAssessment: INewRiskAssessment,
  { fetcher, options }: IFetchRequest,
): Promise<IRiskAssessment> => {
  if (!id) throw new Error('id is required');

  const { analystType, ...body } = riskAssessment;
  return fetcher.post(
    `${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/risk-assessments/${analystType === RiskAnalystType.UNDERWRITER_LEVEL_1 ? 'level-one' : 'level-two'}`,
    {
      body: JSON.stringify(body),
      ...options,
    },
  );
};

export const getOffers = (id: string | undefined, { fetcher, options }: IFetchRequest): Promise<IOffer[]> => {
  if (!id) throw new Error('id is required');
  return fetcher.get(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/offers`, options);
};

export const addOffer = (
  id: string | undefined,
  offer: INewOffer,
  { fetcher, options }: IFetchRequest,
): Promise<IOffer> => {
  if (!id) throw new Error('id is required');
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/offers`, {
    body: JSON.stringify(offer),
    ...options,
  });
};

export const updateLoanApplication = (
  id: string,
  loanApplication: ILoanApplication,
  { fetcher, options }: IFetchRequest,
): Promise<ILoanApplication> => {
  return fetcher.patch(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}`, {
    body: JSON.stringify(loanApplication),
    ...options,
  });
};

export const deleteLoanApplication = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.delete(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}`, options);
};

export const calculatorLoanApplication = (
  id: string,
  body: IRequestCalculator,
  { fetcher, options }: IFetchRequest,
): Promise<any> => {
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/calculator`, {
    body: JSON.stringify(body),
    ...options,
  });
};

export const submitLoanApplication = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/submit`, options);
};

export const underwriteLoanApplication = (
  id: string | undefined,
  { fetcher, options }: IFetchRequest,
): Promise<void> => {
  if (!id) throw new Error('id is required');
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/underwrite`, options);
};

export const approveLoanApplication = (id: string | undefined, { fetcher, options }: IFetchRequest): Promise<void> => {
  if (!id) throw new Error('id is required');
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/approve`, options);
};

export const rejectLoanApplication = (
  id: string | undefined,
  rejectionReason: string,
  { fetcher, options }: IFetchRequest,
): Promise<void> => {
  if (!id) throw new Error('id is required');
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/reject`, {
    body: JSON.stringify({ rejectionReason }),
    ...options,
  });
};

export const signLoanApplication = (id: string | undefined, { fetcher, options }: IFetchRequest): Promise<void> => {
  if (!id) throw new Error('id is required');
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/sign`, options);
};

export const deferLoanApplication = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/defer`, options);
};

export const disburseLoanApplication = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/disburse`, options);
};

export const setBankDetailsLoanApplication = (
  id: string | undefined,
  iban: string,
  bank: string,
  { fetcher, options }: IFetchRequest,
): Promise<void> => {
  if (!id) throw new Error('id is required');
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/bank-details`, {
    body: JSON.stringify({ bank, iban }),
    ...options,
  });
};

export const createNoteForLoanApplication = (
  id: string | undefined,
  text: string,
  { fetcher, options }: IFetchRequest,
): Promise<void> => {
  if (!id) throw new Error('id is required');
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/notes`, {
    body: JSON.stringify({ text }),
    ...options,
  });
};

export const amlScreenLoanApplicationBusiness = (
  id: string | undefined,
  { fetcher, options }: IFetchRequest,
): Promise<void> => {
  if (!id) throw new Error('id is required');
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/aml/business`, options);
};

export const amlScreenLoanApplicationUser = (
  id: string | undefined,
  { fetcher, options }: IFetchRequest,
): Promise<void> => {
  if (!id) throw new Error('id is required');
  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/${id}/aml/user`, options);
};
