import {
  IRiskAssessment,
  loanApplicationQueryKeys,
  LoanApplicationState,
  queryClient,
  RiskAnalystType,
  useAddRiskAssessmentMutation,
  useRiskAssessmentsQuery,
} from '@nayla/common';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import CircularProgress from '@stryberventures/gaia-react.circular-progress';
import Tag from '@stryberventures/gaia-react.tag';
import RiskAnalysisView from '@/screens/LoanApplications/detail/tabs/risk-assessment/risk-analysis-view';
import RiskAnalysisForm from '@/screens/LoanApplications/detail/tabs/risk-assessment/risk-analysis-form';
import useStyles from './styles';
import Button from '@stryberventures/gaia-react.button';
import { enqueueSnackbar } from 'notistack';
import adminVocab from '@/vocabulary';
import { useState } from 'react';
import { ModalDialog } from '@/components/Dialog';
import dayjs from 'dayjs';
import { loanApplicationDetailViewExpandArray } from '@/screens/LoanApplications/detail';

export interface CreditRiskAnalysisProps {
  preliminaryAssessmentResult: LoanApplicationState | 'NA';
}

const CreditRiskAnalysisTwoTab = ({ preliminaryAssessmentResult }: CreditRiskAnalysisProps) => {
  const data = useAtomValue(loanApplication);
  const classes = useStyles();
  const [confirmModalShown, setConfirmModalShown] = useState(false);
  const { data: riskAssessments, isLoading, isError, error } = useRiskAssessmentsQuery(data?.id);
  const { mutate: addRiskAssessment } = useAddRiskAssessmentMutation({
    onSettled: () => {
      queryClient.invalidateQueries(
        loanApplicationQueryKeys.loanApplication(data?.id, loanApplicationDetailViewExpandArray),
      );
      queryClient.invalidateQueries(loanApplicationQueryKeys.riskAssessments(data?.id));
    },
    onSuccess: () => {
      enqueueSnackbar(adminVocab.toasts.success.updated(adminVocab.loanApplication.riskAssessment.entityName), {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(adminVocab.toasts.errors.update(adminVocab.loanApplication.riskAssessment.entityName), {
        variant: 'error',
      });
    },
  });

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <Tag color="error">{`Error: ${error}`}</Tag>;
  }

  const sortedRiskAssessments = riskAssessments?.sort(
    (a: IRiskAssessment, b: IRiskAssessment) => dayjs(a?.createdAt).valueOf() - dayjs(b?.createdAt).valueOf(),
  );

  const firstRiskAssessment = sortedRiskAssessments?.[0];
  const secondRiskAssessment = sortedRiskAssessments?.[1];

  if (secondRiskAssessment) {
    return (
      <RiskAnalysisView
        riskAssessment={secondRiskAssessment}
        preliminaryAssessmentResult={preliminaryAssessmentResult}
      />
    );
  }

  if (!firstRiskAssessment) {
    return <p>Go to the previous tab and submit your first risk assessment.</p>;
  }

  const confirmFirstAssessment = () => {
    addRiskAssessment({
      loanApplicationId: data?.id,
      riskAssessment: {
        creditScore: firstRiskAssessment?.creditScore,
        maximumAmount: firstRiskAssessment?.maximumAmount,
        riskCategoryId: firstRiskAssessment?.riskCategory?.id,
        comment: 'Second risk assessment confirms the first one.',
        rejected: firstRiskAssessment.rejected,
        analystType: RiskAnalystType.UNDERWRITER_LEVEL_2,
      },
    });
    setConfirmModalShown(false);
  };

  return (
    <div className={classes.container}>
      {/* <div className={classes.confirmAnalysisOne}>
        <p>I want to confirm that the Credit Risk Assessment 1 is correct and reviewed. </p>
        <Button onClick={() => setConfirmModalShown(true)} variant="outlined" className={classes.btn}>
          Confirm Analysis 1
        </Button>
      </div> */}
      {/* <div className={classes.divider}></div> */}
      <div className={classes.confirmAnalysisTwo}>
        {/* <p>Or, I want to do and submit a new Credit Risk Assessment:</p> */}
        <p>Submit a new Credit Risk Assessment:</p>

        <RiskAnalysisForm
          preliminaryAssessmentResult={preliminaryAssessmentResult}
          riskAnalystType={RiskAnalystType.UNDERWRITER_LEVEL_2}
        />
      </div>
      {confirmModalShown && (
        <ModalDialog
          open={confirmModalShown}
          setOpen={setConfirmModalShown}
          title={adminVocab.loanApplication.riskAssessment.confirmTitle}
          onSubmit={confirmFirstAssessment}
        >
          {adminVocab.loanApplication.riskAssessment.confirmDescription}
        </ModalDialog>
      )}
    </div>
  );
};

export default CreditRiskAnalysisTwoTab;
