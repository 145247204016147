import { FC, useState } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import {
  formatDate,
  getTagColorByLoanApplicationState,
  loanApplicationQueryKeys,
  LoanApplicationState,
  numberWithCommas,
  queryClient,
  useDeferLoanApplicationMutation,
} from '@nayla/common';
import adminVocab from '@/vocabulary';
import Tag from '@stryberventures/gaia-react.tag';
import Button from '@stryberventures/gaia-react.button';
import { enqueueSnackbar } from 'notistack';
import { loanApplicationDetailViewExpandArray } from '@/screens/LoanApplications/detail';
import { ModalDialog } from '@/components/Dialog';

const LoanApplicationOverview: FC = () => {
  const classes = useStyles();
  const data = useAtomValue(loanApplication);
  const [confirmModalShown, setConfirmModalShown] = useState(false);
  const termPeriod = data?.terms ? `${data?.terms.toString()} ${adminVocab.loanApplication.monthsLabel}` : '';
  const { mutate: deferLoanApplication } = useDeferLoanApplicationMutation({
    onSuccess: () => {
      enqueueSnackbar(adminVocab.loanApplication.transitions.deferred, { variant: 'success' });
    },
    onError: (error: any) => {
      enqueueSnackbar(error, { variant: 'error' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        loanApplicationQueryKeys.loanApplication(data?.id, loanApplicationDetailViewExpandArray),
      );
    },
  });

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <h4 className={classes.businessName}>{data?.business?.companyName ?? ''} </h4>
        <div>{`${adminVocab.loanApplication.applicationID} ${data?.externalId ?? ''}`}</div>
      </div>
      <div className={classes.content}>
        <div className={classes.contentColumn}>
          <p>{adminVocab.loanApplication.loanAmountRequest}:</p>
          <p className={classes.loanTerms}>{data?.amount ? numberWithCommas(data?.amount) : adminVocab.na}</p>
          <div>{`${adminVocab.loanApplication.applicationDate}: ${formatDate(data?.createdAt)}`}</div>
        </div>
        <div className={classes.contentColumn}>
          <p>{adminVocab.loanApplication.termRequest}:</p>
          <p className={classes.loanTerms}>{termPeriod}</p>
          <div>{`${adminVocab.loanApplication.companyCR}: ${data?.business?.commercialRegistrationNumber || ''}`}</div>
        </div>
        <div className={classes.contentColumn}>
          {data?.state ? (
            <>
              <p>{`${adminVocab.loanApplication.status}:`}</p>
              <Tag className={classes.chip} color={getTagColorByLoanApplicationState(data.state)}>
                {data?.state ? adminVocab.loanApplication.states[data.state] : adminVocab.na}
              </Tag>
            </>
          ) : (
            <span></span>
          )}
          {data?.state === LoanApplicationState.CREATE_OFFER ? (
            <>
              <Button onClick={() => setConfirmModalShown(true)} className={classes.deferButton}>
                {adminVocab.loanApplication.deferApplication}
              </Button>
              {confirmModalShown && (
                <ModalDialog
                  open={confirmModalShown}
                  setOpen={setConfirmModalShown}
                  title={adminVocab.loanApplication.confirmDeferral}
                  onSubmit={() => {
                    setConfirmModalShown(false);
                    deferLoanApplication(data?.id);
                  }}
                >
                  {adminVocab.loanApplication.deferDescription}
                </ModalDialog>
              )}
            </>
          ) : (
            <span></span>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoanApplicationOverview;
