import { Ref, useState, useCallback, useEffect } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import { ICalculator, RiskCategoryEnum, useCalculatorLoanProductMutation, useLoanProductQuery } from '@nayla/common';
import { CalculatorForm } from '../CalculatorForm';
import Layout from '../../Layout';

interface ICalculatorLoanProps {
  loanProductId: string;
  handleSubmit: (data: { amount: string; terms: number }) => void;
  formRef?: Ref<IFormRef> | undefined;
  includeRiskAssessment?: boolean;
  initial?: {
    amount: string;
    terms: number;
  };
}

export const CalculatorLoan = ({
  handleSubmit,
  formRef,
  loanProductId,
  includeRiskAssessment,
  initial,
}: ICalculatorLoanProps) => {
  const [calculatorValues, setCalculatorValues] = useState<ICalculator>();

  // Hooks must be called unconditionally at the top level of the component
  const { mutate: calculate, isLoading } = useCalculatorLoanProductMutation({
    onSettled: () => {},
    onSuccess: (data: ICalculator) => {
      setCalculatorValues(data);
    },
  });

  const { data: loanProduct, isLoading: isLoanProductLoading } = useLoanProductQuery(loanProductId, ['riskCategories']);

  const handleCalculation = useCallback(
    (amount: string, terms: number, riskValue?: RiskCategoryEnum, start?: string) => {
      const riskCategoryId = loanProduct?.riskCategories?.find((riskCategory) => {
        return riskCategory.name === riskValue;
      })?.id;

      calculate({
        id: loanProductId,
        calculatorData: {
          start: start,
          riskCategoryId: riskCategoryId,
          amount: amount,
          terms: terms,
        },
      });
      handleSubmit({
        amount: amount,
        terms: terms,
      });
    },
    [loanProduct, loanProductId, calculate],
  );

  if (isLoanProductLoading) {
    return <p>Loading...</p>;
  }

  if (!loanProduct) {
    return <Layout>Error occurred while fetching the loan product. Please try again later.</Layout>;
  }

  return (
    <CalculatorForm
      initAmount={initial?.amount}
      initTerms={initial?.terms}
      minLoanAmount={loanProduct?.minLoanAmount}
      maxLoanAmount={loanProduct?.maxLoanAmount}
      minLoanTerm={loanProduct?.minTerms}
      maxLoanTerm={loanProduct?.maxTerms}
      value={calculatorValues}
      isLoading={isLoading}
      handleCalculation={handleCalculation}
      handleSubmit={handleSubmit}
      riskCategories={includeRiskAssessment ? loanProduct?.riskCategories : undefined}
      formRef={formRef}
    />
  );
};
