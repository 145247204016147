import { IOffer } from './offer';
import { IBusiness } from './business';

export enum ContractState {
  EJECT = 'EJECT',
  NO_RESPONSE = 'NO_RESPONSE',
  PARTIALLY_SIGNED = 'PARTIALLY_SIGNED',
  CLOSED = 'CLOSED',
  SIGNED = 'SIGNED',
}

export interface IContract {
  id: string;
  contractName: string;
  contractNumber: string;
  contractLink: string;
  contractLinkAdmin: string;
  contractStatus: ContractState;
  offerId: string;
  offer: IOffer;
  business: IBusiness;
  createdAt: string;
  updatedAt: string;
}
export interface IContractDetails {
  data: {
    beneficiaryIDNumbers: string[];
    contractNumber: string;
    contractStatusCode: string;
    createdOn: string;
    customerCR: string;
    fileSharingLink: string;
    signDate: string;
    signFile: string;
    unSignFile: string;
    unSignFileLink: string;
  };
}

export interface IUploadContract {
  offerId: string;
  loanApplicationId: string;
}
