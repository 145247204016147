import { useFetchWrapper } from '@nayla/common';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

export const useExtendSession = () => {
  const { mutate: extendSessionOrRedirect } = useExtendSessionMutation();

  useEffect(() => {
    window.addEventListener('mousemove', extendOnMouseMove);
    window.addEventListener('click', extendOnClick);

    return () => {
      // cleanup
      window.removeEventListener('mousemove', extendOnMouseMove);
      window.removeEventListener('click', extendOnClick);
    };
  }, []);

  function extendOnMouseMove() {
    debounceSessionExtend();
  }

  function extendOnClick() {
    debounceSessionExtend();
  }

  let wait = false;

  function debounceSessionExtend() {
    if (!wait) {
      extendSessionOrRedirect();
      wait = true;
      setTimeout(() => {
        wait = false;
      }, 1000 * 60); // call extend once per minute
    }
  }
};

const useExtendSessionMutation = () => {
  const fetcher = useFetchWrapper();

  return useMutation({
    mutationFn: () => {
      return fetcher.post('/api/auth/session/extend').then(() => fetcher.get('/sessions/whoami'));
    },
  });
};
