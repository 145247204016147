import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import Tag from '@stryberventures/gaia-react.tag';
import { IRiskAssessment, LoanApplicationState, RiskAnalystType, useRiskAssessmentsQuery } from '@nayla/common';
import CircularProgress from '@stryberventures/gaia-react.circular-progress';
import RiskAnalysisView from '@/screens/LoanApplications/detail/tabs/risk-assessment/risk-analysis-view';
import RiskAnalysisForm from '@/screens/LoanApplications/detail/tabs/risk-assessment/risk-analysis-form';
import dayjs from 'dayjs';

export interface CreditRiskAnalysisProps {
  preliminaryAssessmentResult: LoanApplicationState | 'NA';
}

const CreditRiskAnalysisOneTab = ({ preliminaryAssessmentResult }: CreditRiskAnalysisProps) => {
  const data = useAtomValue(loanApplication);
  const { data: riskAssessments, isLoading, isError, error } = useRiskAssessmentsQuery(data?.id);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <Tag color="error">{`Error: ${error}`}</Tag>;
  }

  const sortedRiskAssessments = riskAssessments?.sort(
    (a: IRiskAssessment, b: IRiskAssessment) => dayjs(a?.createdAt).valueOf() - dayjs(b?.createdAt).valueOf(),
  );

  const riskAssessment = sortedRiskAssessments?.[0];
  if (riskAssessment) {
    /* TODO: we hardcode this for now in order to show only the first risk assessment. In reality this can be scaled to [] per loan app */
    return (
      <RiskAnalysisView riskAssessment={riskAssessment} preliminaryAssessmentResult={preliminaryAssessmentResult} />
    );
  }

  return (
    <RiskAnalysisForm
      preliminaryAssessmentResult={preliminaryAssessmentResult}
      riskAnalystType={RiskAnalystType.UNDERWRITER_LEVEL_1}
    />
  );
};

export default CreditRiskAnalysisOneTab;
