import React, { useEffect } from 'react';
import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import InputPassword from '@stryberventures/gaia-react.input-password';
import Button from '@stryberventures/gaia-react.button';
import useStyles from './styles';
import * as yup from 'yup';
import { NavLink, useNavigate } from 'react-router-dom';
import ButtonGoogleLogin from '@/components/ButtonGoogleLogin';
import adminVocab from '@/vocabulary';
import Text from '@/components/Text';
import { enqueueSnackbar } from 'notistack';
import { useAtom, useSetAtom } from 'jotai';
import {
  authState,
  IAuthResponse,
  newPasswordChallengeState,
  ILoginAttempt,
  useLoginMutation,
  useOAuthLoginMutation,
} from '@nayla/common';

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
});

const Login = () => {
  const classes = useStyles();
  const [disabled, setDisabled] = React.useState(true);
  const [, setToken] = useAtom(authState);
  const setNewPasswordChallengeInfo = useSetAtom(newPasswordChallengeState);
  const navigate = useNavigate();
  const onSuccessOAuth = (response: IAuthResponse) => {
    setToken(response.accessToken);
  };
  const onError = () => {
    enqueueSnackbar(adminVocab.errors.oopsSomethingWentWrong, { variant: 'error' });
    navigate('/');
  };
  const { mutate: loginUser, isLoading: isLoginLoading } = useLoginMutation((response: ILoginAttempt) => {
    if (response) {
      if (response.result === 'TOKENS') {
        setToken(response?.data?.accessToken ? response?.data?.accessToken : '');
        return;
      }

      if (response.result === 'NEW_PASSWORD_REQUIRED') {
        setNewPasswordChallengeInfo(response?.data);
        navigate('/newPasswordChallenge');
      }
    }
  }, onError);

  const { mutate: oauthLoginUser, isLoading: isOAuthLoginLoading } = useOAuthLoginMutation(onSuccessOAuth, onError);
  const handleSubmit = async (values: any) => {
    await loginUser({
      username: values.email,
      password: values.password,
    });
  };
  const handleGoogleSignIn = async () => {
    const googleAuthorizeUrl = ``;
    window.open(googleAuthorizeUrl, '_self');
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    if (code && state) {
      oauthLoginUser({
        provider: 'Google',
        authorizationCode: code,
      });
    }
  }, [oauthLoginUser]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.logoWrapper}></div>
      <div className={classes.screen}>
        <div className={classes.formContainer}>
          <Text align="center" component="h3" variant="h3" className={classes.title}>
            {adminVocab.authentication.login}
          </Text>
          <ButtonGoogleLogin disabled={isOAuthLoginLoading} onClick={handleGoogleSignIn} />
          <div className={classes.divider}>
            <div className={classes.dividerLine}></div>
            <Text variant="caption1" className={classes.dividerText}>
              {adminVocab.authentication.or}
            </Text>
            <div className={classes.dividerLine}></div>
          </div>
          <Form
            className={classes.form}
            validationSchema={validationSchema}
            onChange={(_, { isValid }) => setDisabled(!isValid)}
            onSubmit={(data: any) => handleSubmit(data)}
          >
            <Input
              name="email"
              fullWidth
              label="Email"
              variant="floatingLabel"
              placeholder={adminVocab.placeholders.email}
              height={56}
            />
            <InputPassword
              name="password"
              fullWidth
              label={adminVocab.users.properties.password}
              variant="floatingLabel"
              placeholder={adminVocab.users.typePassword}
              height={56}
            />
            <Button type="submit" disabled={disabled} fullWidth shape="round" className={classes.button}>
              {isLoginLoading ? adminVocab.loading : adminVocab.continue}
            </Button>
          </Form>
          <div className={classes.linkWrapper}>
            <NavLink to="/forgotPassword">{adminVocab.authentication.forgotPasswordLabel}</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
