import React, { FC, useEffect, useState, useCallback } from 'react';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import useStyles from './styles';
import Button from '@stryberventures/gaia-react.button';
import {
  AMLStatus,
  common,
  ErrorCode,
  getTagColorByAMLState,
  loanApplicationQueryKeys,
  queryClient,
  useFetchWrapper,
} from '@nayla/common';
import { enqueueSnackbar } from 'notistack';
import Tag from '@stryberventures/gaia-react.tag';
import adminVocab from '@/vocabulary';
import { loanApplicationDetailViewExpandArray } from '@/screens/LoanApplications/detail';
import Checkbox from '@stryberventures/gaia-react.checkbox';

const AmlTab: FC = () => {
  const classes = useStyles();
  const loanApp = useAtomValue(loanApplication);
  const fetcher = useFetchWrapper();

  const [amlProfileUuid, setAmlProfileUuid] = useState<string | null>(loanApp?.amlProfileUuid || null);
  const [amlProfileData, setAmlProfileData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshFromFocal, setRefreshFromFocal] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const getFocalLink = useCallback((id: string) => {
    return `https://app.sa.focal.mozn.sa/records/customers/${id}/profile`;
  }, []);

  const fetchAmlProfileData = useCallback(
    async (uuid: string) => {
      if (!uuid) return;
      setIsLoading(true);
      try {
        const response = await fetcher.get(
          `${common.endpoints.path.API_AML_ENDPOINT}/${uuid}?refreshScreen=${refreshFromFocal}`,
        );
        setAmlProfileData(response.amlProfile);
      } catch (error) {
        if (typeof error === 'object' && error && 'errorCode' in error && error?.errorCode === ErrorCode.FORBIDDEN) {
          enqueueSnackbar(adminVocab.errors.accessDenied, {
            variant: 'error',
          });
        } else {
          console.error('Error fetching AML profile data:', error);
        }
      } finally {
        setIsLoading(false);
        setInitialLoadComplete(true);
      }
    },
    [fetcher, refreshFromFocal],
  );

  const runAML = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetcher.post(`${common.endpoints.path.API_AML_ENDPOINT}/create`, {
        body: JSON.stringify({ loanApplicationId: loanApp?.id }),
      });
      const newAmlProfileUuid = response.amlProfileUuid;
      setAmlProfileUuid(newAmlProfileUuid);
      setAmlProfileData(response);
      enqueueSnackbar(adminVocab.loanApplication.aml.amlRunSuccess, { variant: 'success' });
      queryClient.invalidateQueries(
        loanApplicationQueryKeys.loanApplication(loanApp?.id ?? '', loanApplicationDetailViewExpandArray),
      );
    } catch (error) {
      if (typeof error === 'object' && error && 'errorCode' in error && error?.errorCode === ErrorCode.FORBIDDEN) {
        enqueueSnackbar(adminVocab.errors.accessDenied, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(adminVocab.loanApplication.aml.amlRunFailed, { variant: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  }, [loanApp?.id, fetcher]);

  const refreshAML = useCallback(async () => {
    if (!amlProfileUuid) {
      enqueueSnackbar(adminVocab.loanApplication.aml.amlRunFailed, { variant: 'error' });
      return;
    }
    await fetchAmlProfileData(amlProfileUuid);
    enqueueSnackbar(adminVocab.loanApplication.aml.amlRefreshSuccess, { variant: 'success' });
    queryClient.invalidateQueries(
      loanApplicationQueryKeys.loanApplication(loanApp?.id ?? '', loanApplicationDetailViewExpandArray),
    );
  }, [amlProfileUuid, loanApp?.id]);

  useEffect(() => {
    if (amlProfileUuid) {
      fetchAmlProfileData(amlProfileUuid);
    } else {
      setIsLoading(false);
      setInitialLoadComplete(true);
    }
  }, [amlProfileUuid]);

  useEffect(() => {
    if (loanApp?.amlProfileUuid) {
      setAmlProfileUuid(loanApp.amlProfileUuid);
    }
  }, [loanApp?.amlProfileUuid]);

  const amlWasRun = !!amlProfileUuid;
  const runBtnDisabled = amlWasRun || isLoading;
  const refreshBtnDisabled = !amlWasRun || isLoading;

  if (!initialLoadComplete) {
    return <div>Loading...</div>;
  }

  return (
    <div className={classes.container}>
      {amlProfileData ? (
        <div className={classes.amlInfo}>
          <div className={classes.amlRow}>
            <span style={{ fontWeight: 600 }}>{adminVocab.loanApplication.aml.clientResults}:</span>
            <Tag
              style={{
                textDecoration:
                  amlProfileData?.individualScreening?.status === AMLStatus.ACTION_REQUIRED ? 'underline' : '',
              }}
              onClick={() => {
                if (
                  amlProfileData?.individualScreening?.status === AMLStatus.ACTION_REQUIRED &&
                  amlProfileData?.individualScreening?.result?.customerReferenceId
                ) {
                  window.open(getFocalLink(amlProfileData?.individualScreening?.result?.customerReferenceId), '_blank');
                }
              }}
              color={getTagColorByAMLState(amlProfileData?.individualScreening?.status)}
            >
              {adminVocab.loanApplication.amlStatuses[amlProfileData?.individualScreening?.status as AMLStatus] ??
                adminVocab.loanApplication.amlStatuses.NONE}
            </Tag>
          </div>
          <div className={classes.amlRow}>
            <span style={{ fontWeight: 600 }}>{adminVocab.loanApplication.aml.companyResults}:</span>
            <Tag
              style={{
                textDecoration:
                  amlProfileData?.organizationScreening?.status === AMLStatus.ACTION_REQUIRED ? 'underline' : '',
              }}
              onClick={() => {
                if (
                  amlProfileData?.organizationScreening?.status === AMLStatus.ACTION_REQUIRED &&
                  amlProfileData?.organizationScreening?.result?.customerReferenceId
                ) {
                  window.open(
                    getFocalLink(amlProfileData?.organizationScreening?.result?.customerReferenceId),
                    '_blank',
                  );
                }
              }}
              color={getTagColorByAMLState(amlProfileData?.organizationScreening?.status)}
            >
              {adminVocab.loanApplication.amlStatuses[amlProfileData?.organizationScreening?.status as AMLStatus] ??
                adminVocab.loanApplication.amlStatuses.NONE}
            </Tag>
          </div>
        </div>
      ) : (
        <div>{adminVocab.loanApplication.aml.noAmlProfile}</div>
      )}
      <div className={classes.btnContainer}>
        <Button className={classes.btn} disabled={runBtnDisabled} onClick={runAML} variant="outlined">
          {adminVocab.loanApplication.aml.runAML}
        </Button>
        <div className={classes.refreshContainer}>
          <Button className={classes.btn} disabled={refreshBtnDisabled} onClick={refreshAML} variant="outlined">
            {adminVocab.loanApplication.aml.refreshAML}
          </Button>
          {!refreshBtnDisabled && (
            <Checkbox
              className={classes.checkbox}
              checked={refreshFromFocal}
              onChange={(e) => setRefreshFromFocal(e.target.checked)}
              label="Refresh AML data from Focal"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(AmlTab);
