import useStyles from './styles';
import adminVocab from '@/vocabulary';
import { useState } from 'react';
import ChangePasswordModal from '@/components/ChangePasswordModal';
import { useAtom } from 'jotai';
import { authState, queryClient, useFetchWrapper } from '@nayla/common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

interface MenuItem {
  label: string;
  onClick?: () => void;
  hidden?: boolean;
}

interface IProfileMenuItems {
  setProfilePopupState: (state: boolean) => void;
}

const UserProfileMenuItems = ({ setProfilePopupState }: IProfileMenuItems) => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [, setToken] = useAtom(authState);
  const menuItems: MenuItem[] = [
    {
      label: adminVocab.authentication.changePassword,
      onClick: () => setShowChangePasswordModal(true),
      hidden: false,
    },
  ];
  const classes = useStyles();
  const { mutate: logout } = useLogoutMutation();

  return (
    <>
      {menuItems?.length > 0 && (
        <div className={classes.menuContainer}>
          {menuItems
            .filter((el) => !el.hidden)
            .map((menuItem, index) => {
              return (
                <div className={classes.menuItem} onClick={menuItem.onClick} key={index}>
                  {menuItem.label}
                </div>
              );
            })}
          <div className={classes.menuItem} onClick={() => logout()}>
            {adminVocab.authentication.signOut}
          </div>
        </div>
      )}
      {showChangePasswordModal && (
        <ChangePasswordModal
          setOpen={(state: boolean) => {
            setShowChangePasswordModal(state);
            setProfilePopupState(state);
          }}
        />
      )}
    </>
  );
};

interface LogoutResponse {
  logoutUrl: string;
}

const useLogoutMutation = (options?: UseMutationOptions<LogoutResponse>) => {
  const fetcher = useFetchWrapper();
  return useMutation({
    mutationFn: () => fetcher.post(`/api/auth/signout`),
    ...options,
    onSuccess: async (...params) => {
      options?.onSuccess?.(...params);
      await queryClient.resetQueries();
      window.location.href = params?.[0]?.logoutUrl;
    },
  });
};

export default UserProfileMenuItems;
