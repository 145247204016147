import React, { useState, FC } from 'react';
import { Table, Users, Shield } from 'lucide-react';
import Layout from '@nayla/ui/components/Layout';
import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';
import { MEDIA_QUERIES } from '@nayla/common/hooks/useViewport';
// Add these constant arrays at the top of your file, after the imports
const ADMIN_STATUSES = [
  {
    key: 'SUBMIT',
    english: 'Submitted',
    arabic: 'تم التقديم',
    color: 'bg-blue-100 text-blue-800',
  },
  {
    key: 'UNDERWRITER_LEVEL_1',
    english: 'Underwriter Level 1',
    arabic: 'مكتتب المستوى الأول',
    color: 'bg-purple-100 text-purple-800',
  },
  {
    key: 'UNDERWRITER_LEVEL_2',
    english: 'Underwriter Level 2',
    arabic: 'مكتتب المستوى الثاني',
    color: 'bg-purple-100 text-purple-800',
  },
  {
    key: 'CREATE_OFFER',
    english: 'Create Offer',
    arabic: 'إنشاء عرض',
    color: 'bg-yellow-100 text-yellow-800',
  },
  {
    key: 'ACCEPT_OFFER',
    english: 'Accept Offer',
    arabic: 'قبول العرض',
    color: 'bg-yellow-100 text-yellow-800',
  },
  {
    key: 'COMMODITY_BUY',
    english: 'Commodity Buy',
    arabic: 'شراء السلع',
    color: 'bg-orange-100 text-orange-800',
  },
  {
    key: 'AML',
    english: 'AML Check',
    arabic: 'فحص مكافحة غسل الأموال',
    color: 'bg-blue-100 text-blue-800',
  },
  {
    key: 'CONTRACT',
    english: 'Contract',
    arabic: 'العقد',
    color: 'bg-indigo-100 text-indigo-800',
  },
  {
    key: 'COMMODITY_SELL',
    english: 'Commodity Sell',
    arabic: 'بيع السلع',
    color: 'bg-orange-100 text-orange-800',
  },
  {
    key: 'IBAN',
    english: 'IBAN Verification',
    arabic: 'التحقق من IBAN',
    color: 'bg-cyan-100 text-cyan-800',
  },
  {
    key: 'PENDING_IVR',
    english: 'IVR Confirmation',
    arabic: 'تأكيد IVR',
    color: 'bg-violet-100 text-violet-800',
  },
  {
    key: 'IN_PROGRESS_IVR',
    english: 'In Progress IVR',
    arabic: 'المكالمة الهاتفية قيد التأكيد على طلبك',
    color: 'bg-violet-100 text-violet-800',
  },
  {
    key: 'PENDING_DISBURSE',
    english: 'Pending Disburse',
    arabic: 'قيد الصرف',
    color: 'bg-emerald-100 text-emerald-800',
  },
  {
    key: 'SUCCESS_DISBURSE',
    english: 'Success Disburse',
    arabic: 'تم الصرف',
    color: 'bg-green-100 text-green-800',
  },
  {
    key: 'REJECTED',
    english: 'Rejected',
    arabic: 'مرفوض',
    color: 'bg-red-100 text-red-800',
  },
  {
    key: 'EXPIRED',
    english: 'Expired',
    arabic: 'منتهي الصلاحية',
    color: 'bg-gray-100 text-gray-800',
  },
  {
    key: 'DEFERRED',
    english: 'Deferred',
    arabic: 'مؤجل',
    color: 'bg-yellow-100 text-yellow-800',
  },
];

const USER_STATUSES = [
  {
    key: 'submitted',
    english: 'Submitted',
    arabic: 'وصلنا طلبك',
    color: 'bg-blue-100 text-blue-800',
  },
  {
    key: 'underReview',
    english: 'Under-review',
    arabic: 'تحت المراجعة',
    color: 'bg-purple-100 text-purple-800',
  },
  {
    key: 'preparingOffer',
    english: 'Preparing the offer',
    arabic: 'جاري إعداد العرض',
    color: 'bg-yellow-100 text-yellow-800',
  },
  {
    key: 'pendingOfferAcceptance',
    english: 'Waiting for Offer Acceptance',
    arabic: 'بإنتظار قبولك للعرض',
    color: 'bg-yellow-100 text-yellow-800',
  },
  {
    key: 'pendingContractSignature',
    english: 'Awaiting Contract Signature',
    arabic: 'بانتظار توقيعك للعقد',
    color: 'bg-indigo-100 text-indigo-800',
  },
  {
    key: 'pendingCommodityAuth',
    english: 'Pending authorization',
    arabic: 'بانتظار تفويضيك لبيع السلعة',
    color: 'bg-orange-100 text-orange-800',
  },
  {
    key: 'ibanVerification',
    english: 'Awaiting your bank details',
    arabic: 'بانتظار بياناتك البنكية',
    color: 'bg-cyan-100 text-cyan-800',
  },
  {
    key: 'pendingIVR',
    english: 'Pending loan application Confirmation',
    arabic: 'بانتظار تأكيدك لطلب التمويل',
    color: 'bg-violet-100 text-violet-800',
  },
  {
    key: 'pendingDisburse',
    english: 'Pending Disbursement',
    arabic: 'بانتظار ايداع التمويل',
    color: 'bg-emerald-100 text-emerald-800',
  },
  {
    key: 'successDisburse',
    english: 'Disbursement Complete',
    arabic: 'تم ايداع التمويل',
    color: 'bg-green-100 text-green-800',
  },
  {
    key: 'rejected',
    english: 'Rejected',
    arabic: 'مرفوض',
    color: 'bg-red-100 text-red-800',
  },
  {
    key: 'expired',
    english: 'Expired',
    arabic: 'منتهي الصلاحية',
    color: 'bg-gray-100 text-gray-800',
  },
  {
    key: 'deferred',
    english: 'Deferred',
    arabic: 'مؤجل',
    color: 'bg-yellow-100 text-yellow-800',
  },
];
// Styles
const useStyles = createUseStyles({
  root: {
    minHeight: '100vh',
    background: theme.colors.background.extraLightGrey,
    padding: toRem(32),
  },
  container: {
    maxWidth: toRem(1200),
    margin: '0 auto',
  },
  headerCard: {
    background: theme.colors.background.white,
    borderRadius: toRem(10),
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    border: `1px solid ${theme.colors.primary}`,
    padding: toRem(24),
    marginBottom: toRem(24),
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(16),
    [MEDIA_QUERIES.desktop]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  titleGroup: {
    '& h1': {
      fontSize: toRem(24),
      fontWeight: 700,
      color: theme.colors.text.headline,
      marginBottom: toRem(4),
    },
    '& p': {
      fontSize: toRem(14),
      color: theme.colors.text.secondary,
    },
  },
  tableCard: {
    background: theme.colors.background.white,
    borderRadius: toRem(10),
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    border: `1px solid ${theme.colors.primary}`,
    overflow: 'hidden',
  },
  tableHeader: {
    padding: `${toRem(16)} ${toRem(24)}`,
    borderBottom: `1px solid ${theme.colors.primary}`,
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      gap: toRem(12),
      marginBottom: toRem(4),
      '& h2': {
        fontSize: toRem(18),
        fontWeight: 600,
        color: theme.colors.text.headline,
      },
    },
    '& .description': {
      marginLeft: toRem(32),
      fontSize: toRem(14),
      color: theme.colors.text.secondary,
    },
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  th: {
    padding: `${toRem(16)} ${toRem(24)}`,
    textAlign: 'left',
    fontSize: toRem(12),
    fontWeight: 600,
    color: theme.colors.text.secondary,
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    background: theme.colors.background.extraLightGrey,
    '&.arabic': {
      textAlign: 'right',
    },
  },
  tr: {
    transition: 'background-color 0.2s',
    '&:hover': {
      background: theme.colors.background.extraLightGrey,
    },
    '&:nth-child(even)': {
      background: theme.colors.background.extraLightGrey,
      '&:hover': {
        background: theme.colors.background.extraLightGrey,
      },
    },
  },
  td: {
    padding: `${toRem(16)} ${toRem(24)}`,
    fontSize: toRem(14),
    color: theme.colors.text.headline,
    borderTop: `1px solid ${theme.colors.primary}`,
    '&.arabic': {
      textAlign: 'right',
      fontFamily: 'var(--font-arabic)',
    },
  },
  statusKey: {
    fontFamily: 'monospace',
    fontSize: toRem(12),
    color: theme.colors.text.secondary,
    background: theme.colors.background.extraLightGrey,
    padding: `${toRem(4)} ${toRem(8)}`,
    borderRadius: toRem(4),
    display: 'inline-block',
  },
  statusBadge: {
    padding: `${toRem(4)} ${toRem(12)}`,
    borderRadius: toRem(16),
    fontSize: toRem(12),
    fontWeight: 600,
    display: 'inline-flex',
    alignItems: 'center',
    transition: 'opacity 0.2s',
    '&:hover': {
      opacity: 0.8,
    },
  },
  footer: {
    padding: `${toRem(16)} ${toRem(24)}`,
    borderTop: `1px solid ${theme.colors.primary}`,
    background: theme.colors.background.extraLightGrey,
    fontSize: toRem(12),
    color: theme.colors.text.secondary,
  },
  legend: {
    marginTop: toRem(24),
    background: theme.colors.background.white,
    borderRadius: toRem(10),
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    border: `1px solid ${theme.colors.primary}`,
    padding: toRem(24),
    '& h3': {
      fontSize: toRem(14),
      fontWeight: 600,
      color: theme.colors.text.headline,
      marginBottom: toRem(12),
    },
  },
  legendGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: toRem(16),
    [MEDIA_QUERIES.desktop]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    gap: toRem(8),
    '& .dot': {
      width: toRem(12),
      height: toRem(12),
      borderRadius: '50%',
    },
    '& span': {
      fontSize: toRem(12),
      color: theme.colors.text.secondary,
    },
  },
  viewToggle: {
    display: 'flex',
    alignItems: 'center',
    gap: toRem(8),
    padding: toRem(4),
    background: theme.colors.background.white,
    borderRadius: toRem(8),
  },
  toggleButton: {
    display: 'flex',
    alignItems: 'center',
    gap: toRem(8),
    padding: `${toRem(8)} ${toRem(16)}`,
    borderRadius: toRem(6),
    transition: 'all 0.2s',
    fontSize: toRem(14),
    '&.active': {
      background: theme.colors.background.white,
      color: theme.colors.primary.dark600,
      boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
    },
    '&:not(.active)': {
      color: theme.colors.text.secondary,
      '&:hover': {
        color: theme.colors.primary.dark600,
      },
    },
  },
});

// ViewToggle Component
const ViewToggle: FC<{ view: 'admin' | 'user'; setView: (view: 'admin' | 'user') => void }> = ({ view, setView }) => {
  const classes = useStyles();
  return (
    <div className={classes.viewToggle}>
      <button
        onClick={() => setView('admin')}
        className={`${classes.toggleButton} ${view === 'admin' ? 'active' : ''}`}
      >
        <Shield size={16} />
        <span>Admin View</span>
      </button>
      <button onClick={() => setView('user')} className={`${classes.toggleButton} ${view === 'user' ? 'active' : ''}`}>
        <Users size={16} />
        <span>User View</span>
      </button>
    </div>
  );
};

// Main Component
const StatusReferenceTable: FC = () => {
  const [view, setView] = useState<'admin' | 'user'>('admin');
  const classes = useStyles();

  return (
    <Layout title="Status Reference" subtitle="Loan Application Statuses">
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.headerCard}>
            <div className={classes.headerContent}>
              <div className={classes.titleGroup}>
                <h1>Loan Application Status Reference</h1>
                <p>Complete reference of all possible loan application statuses</p>
              </div>
              <ViewToggle view={view} setView={setView} />
            </div>
          </div>

          <div className={classes.tableCard}>
            <div className={classes.tableHeader}>
              <div className="title">
                <Table size={20} className="text-primary" />
                <h2>{view === 'admin' ? 'Administrative Statuses' : 'Customer-Facing Statuses'}</h2>
              </div>
              <p className="description">
                {view === 'admin'
                  ? 'Detailed status list for internal tracking and management'
                  : 'Simplified status descriptions visible to loan applicants'}
              </p>
            </div>

            <div className="overflow-x-auto">
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th className={classes.th}>Status Key</th>
                    <th className={classes.th}>English Label</th>
                    <th className={`${classes.th} arabic`}>Arabic Label</th>
                    <th className={classes.th}>Visual Indicator</th>
                  </tr>
                </thead>
                <tbody>
                  {(view === 'admin' ? ADMIN_STATUSES : USER_STATUSES).map((status) => (
                    <tr key={status.key} className={classes.tr}>
                      <td className={classes.td}>
                        <code className={classes.statusKey}>{status.key}</code>
                      </td>
                      <td className={classes.td}>{status.english}</td>
                      <td className={`${classes.td} arabic`}>{status.arabic}</td>
                      <td className={classes.td}>
                        <span className={`${classes.statusBadge} ${status.color}`}>{status.english}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className={classes.footer}>
              <p>Total Statuses: {(view === 'admin' ? ADMIN_STATUSES : USER_STATUSES).length}</p>
            </div>
          </div>

          {/* <div className={classes.legend}>
            <h3>Color Legend</h3>
            <div className={classes.legendGrid}>
              <div className={classes.legendItem}>
                <div className="dot" style={{ background: theme.colors.primary.dark600 }} />
                <span>Process Started</span>
              </div>
              <div className={classes.legendItem}>
                <div className="dot" style={{ background: theme.colors.warning.dark600 }} />
                <span>Action Required</span>
              </div>
              <div className={classes.legendItem}>
                <div className="dot" style={{ background: theme.colors.success.dark600 }} />
                <span>Completed</span>
              </div>
              <div className={classes.legendItem}>
                <div className="dot" style={{ background: theme.colors.error.dark600 }} />
                <span>Stopped/Rejected</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default StatusReferenceTable;
