import { IOption, ISelect } from './index';
import React, { useState } from 'react';
import { useFormContext } from '@stryberventures/gaia-react.form';
import { IDropdownRef } from '@stryberventures/gaia-react.dropdown';

export const useSelect = (props: ISelect) => {
  const { name = '', error, onChange, value, options } = props;
  const { fieldError, fieldValue, updateFormTouched, updateFormValue } = useFormContext(name);
  const dropdownRef = React.useRef<IDropdownRef>(null);

  const getOptionLabelByValue = (valueOption: string | number) => {
    const option = options.find((optionItem) => optionItem.value === valueOption);
    return option ? option.label : '';
  };
  const [selectedValue, setSelectedValue] = useState<string>(getOptionLabelByValue(fieldValue || value));

  const onDropdownToggle = (open: boolean) => {
    if (!open) {
      updateFormTouched(name, true);
    }
  };

  const onOptionClick = (option: IOption) => {
    updateFormValue(name, option.value);
    setSelectedValue(option.label);
    if (onChange) {
      onChange(option);
    }
    dropdownRef?.current?.close();
  };

  React.useEffect(() => {
    if (fieldValue !== undefined) {
      updateFormValue(name, fieldValue, true);
    }

    return () => {
      if (fieldValue !== undefined) {
        updateFormValue(name, '', true);
      }
    };
  }, [name, updateFormValue]);

  return {
    dropdownRef,
    value: selectedValue,
    error: fieldError || error,
    onOptionClick,
    onDropdownToggle,
  };
};
