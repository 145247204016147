import { AMLStatus, IBusiness } from './business';
import { IAMLData, ILoanApplication, ILoanApplicationStateTransition } from './loan-application';
import { ILoan } from './loan';

export enum TahaqoqStatus {
  NOT_STARTED = 'not_started',
  MATCHED = 'matched',
  NOT_MATCHED = 'not_matched',
}

export enum UserType {
  CUSTOMER = 'customer',
  ORG_ADMIN = 'org_admin',
  ORG_UNDERWRITER_LEVEL_ONE = 'org_underwriter_level_one',
  ORG_UNDERWRITER_LEVEL_TWO = 'org_underwriter_level_two',
  ORG_VIEWER = 'org_viewer',
}

export interface IUser {
  id: string;
  type: UserType;
  idNumber: string;
  externalId: string;
  firstName: string;
  lastName: string;
  address: string;
  profession: string;
  education: string;
  birthdate: string;
  otpStatus: string;
  kycStatus: string;
  amlStatus: AMLStatus;
  verificationDocuments: any;
  blocked: boolean;
  businesses: IBusiness[];
  loanApplications: ILoanApplication[];
  loans: ILoan[];
  loanApplicationChanges: ILoanApplicationStateTransition[];
  isPep?: boolean;
  tahaqoqStatus: TahaqoqStatus;
  /* admin panel properties */
  email?: string;
  enabled?: boolean;
  phone?: string;
  roles?: string[];
  username: string;
  password?: string;
  repeatPassword?: string;
  createdAt?: string;
  updatedAt?: string;
  nationality?: string;
  kycData?: any;
  amlData?: IAMLData;
  addresses?: any;
  riskAssessments?: any;
  notes?: any;
  documents?: any;
}

export interface IUserSnapshot {
  id: string;
  firstName: string;
  lastName: string;
  profession: string;
  education: string;
  createdAt: string;
  updatedAt: string;
  isPep: boolean;
}
