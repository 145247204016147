import { FC } from 'react';
import { UsersTable } from '@/components/UsersTable';
import { IUser, useFetchWrapper } from '@nayla/common';
import Layout from '@nayla/ui/components/Layout';
import adminVocab from '@/vocabulary';
import { useQuery } from '@tanstack/react-query';

const Users: FC = () => {
  const { data } = useOrganizationMembersQuery();

  return (
    <Layout title={adminVocab.users.admins} subtitle={adminVocab.users.tableDescription}>
      <UsersTable rows={data ?? []} />
    </Layout>
  );
};

const useOrganizationMembersQuery = () => {
  const fetcher = useFetchWrapper();

  return useQuery<IUser[]>({
    queryKey: ['organization', 'members'],
    queryFn: () => fetcher.get('/api/organizations/members'),
  });
};

export default Users;
